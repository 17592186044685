module.exports = {
  "GuidedLearning": {
    "cannotBegin": {
      "source": "professional",
      "value": "Cannot begin"
    },
    "completionDate": {
      "context": "(Example) Completed: March 8, 2020",
      "source": "professional",
      "value": "Completed: <%- completed %>"
    },
    "contentHeader": {
      "source": "professional",
      "value": "What's included"
    },
    "details": {
      "checkpointCount": {
        "source": "professional",
        "value": "<%- completed %> of <%- available %> checkpoint(s) achieved"
      },
      "progress": {
        "context": "a title for progress in a Learning Path",
        "source": "professional",
        "value": "Progress"
      }
    },
    "evaluation": {
      "notPassedDate": {
        "context": "The date on which an Evaluation was not passed/failed. (Example) Not Passed: January 28, 2021",
        "source": "professional",
        "value": "Not Passed: <%- attemptDate %>"
      },
      "passedDate": {
        "context": "The date on which an Evaluation was passed. (Example) Passed: January 28, 2021",
        "source": "professional",
        "value": "Passed: <%- attemptDate %>"
      },
      "pending": {
        "source": "professional",
        "value": "Your results aren't in yet. Check again later!"
      }
    },
    "icons": {
      "certification": {
        "source": "professional",
        "value": "Certification"
      },
      "done": {
        "source": "professional",
        "value": "Completed"
      },
      "evaluation": {
        "source": "professional",
        "value": "Evaluation"
      },
      "event_enrollment": {
        "source": "professional",
        "value": "Event"
      },
      "exam": {
        "source": "professional",
        "value": "Exam"
      },
      "locked": {
        "source": "professional",
        "value": "Locked"
      },
      "milestone": {
        "source": "professional",
        "value": "Milestone"
      },
      "needsCoaching": {
        "source": "professional",
        "value": "Needs Coaching"
      },
      "none": {
        "source": "professional",
        "value": "Not completed"
      },
      "pending": {
        "source": "professional",
        "value": "Pending"
      },
      "task": {
        "source": "professional",
        "value": "Task"
      },
      "thumbsUp": {
        "source": "professional",
        "value": "Thumbs Up"
      },
      "topic": {
        "source": "professional",
        "value": "Topic"
      }
    },
    "jumpToNextItem": {
      "source": "professional",
      "value": "Jump to next item"
    },
    "milestoneRow": {
      "bonusReward": {
        "context": "Example: + 8 on-time bonus",
        "source": "professional",
        "value": " + <%- bonusPointsAvailable %> on-time bonus"
      },
      "dueDate": {
        "source": "professional",
        "value": "<%- numComplete %> of <%- numItemsTotal %> items complete &middot; Due <%- humanReadableDate %>"
      },
      "dueDateComplete": {
        "source": "professional",
        "value": "Completed on <%- completionDate %>"
      },
      "duration": {
        "context": "Complete the above within 10 days of enrolling",
        "source": "professional",
        "value": "Complete the above within <%- duration %> days of enrolling"
      },
      "onlyBonusReward": {
        "context": "10 on-time bonus",
        "source": "professional",
        "value": "<%- bonusPointsAvailable %> on-time bonus"
      },
      "rewardPointsText": {
        "source": "professional",
        "value": "Reward Points"
      },
      "startMilestone": {
        "source": "professional",
        "value": "Start Milestone"
      }
    },
    "reassignTraining": {
      "modal": {
        "duplicateReassignError": {
          "context": "Error message that appears when a user attempts to reassign training to another user after that training has already been reassigned to them (Ex: 'Power Equipment Training' cannot be reassigned, this training is already reassigned to this user.)",
          "source": "professional",
          "value": "'<%- pathItemName %>' cannot be reassigned, this training is already reassigned to this user."
        },
        "error": {
          "context": "Error message that appears when an attempt to reassign training to a user fails.",
          "source": "professional",
          "value": "Could not reassign training to user, an unexpected error has occurred."
        },
        "message": {
          "context": "Message that explains that a specific program (name is bolded) will be reassigned to the given user. (Ex: Power Equipment Training will be reassigned to John Doe.)",
          "source": "professional",
          "value": "<b><%- programName %></b> will be reassigned to <%- userName %>."
        },
        "reasonPlaceholder": {
          "context": "Placeholder message for the text field that the user can optionally fill out with the reason the training to be reassigned.",
          "source": "professional",
          "value": "Add reason for reassignment here (optional)"
        },
        "reassign": {
          "context": "Label for the Reassign button.",
          "source": "professional",
          "value": "Reassign"
        },
        "success": {
          "context": "Success message that appears when a user has had training reassigned to them successfully. (Ex: 'Power Equipment Training' has been reassigned to John Doe.)",
          "source": "professional",
          "value": "'<%- pathItemName %>' has been reassigned to <%- userName %>."
        },
        "title": {
          "context": "Title that identifies that this is the Reassign Training modal view.",
          "source": "professional",
          "value": "Reassign Training"
        }
      }
    },
    "statusHeader": {
      "behindHeader": {
        "source": "professional",
        "value": "<%- daysToNextIncompleteMilestone %> days overdue"
      },
      "behindHeaderSingular": {
        "source": "professional",
        "value": "1 day overdue"
      },
      "behindText": {
        "source": "professional",
        "value": "You need to complete the milestones"
      },
      "completed": {
        "context": "User has completed the learning path",
        "source": "professional",
        "value": "You've completed all the milestones!"
      },
      "daysAway": {
        "source": "professional",
        "value": "from next milestone"
      },
      "daysAwayHeader": {
        "context": "How many days a until a milestone is due",
        "source": "professional",
        "value": "Due in <%- daysRemaining %> days"
      },
      "daysAwayHeaderSingular": {
        "context": "only one day until a milestone is due",
        "source": "professional",
        "value": "Due in 1 day"
      },
      "dueDateFromEnrollment": {
        "context": "daysAway is a number referring to the number of days when this milestone will be due when the user enrolls",
        "source": "professional",
        "value": "Due <%- daysAway %> days from enrollment"
      },
      "dueOnceEnrolledSingle": {
        "source": "professional",
        "value": "Due 1 day from enrollment"
      },
      "dueToday": {
        "context": "only one day until a milestone is due",
        "source": "professional",
        "value": "Due today"
      },
      "fallingBehind": {
        "source": "professional",
        "value": "You're falling behind"
      },
      "header": {
        "context": "Title for what the upcoming milestone is",
        "source": "professional",
        "value": "Next Milestone"
      },
      "onTrackHeader": {
        "source": "professional",
        "value": "You're on track!"
      },
      "onTrackText": {
        "source": "professional",
        "value": "Keep it going!"
      },
      "oneDayAwayHeader": {
        "source": "professional",
        "value": "Next Milestone Due"
      },
      "overdue": {
        "source": "professional",
        "value": "Overdue"
      },
      "warningText": {
        "source": "professional",
        "value": "Let's try to do <%- items %> items today"
      },
      "warningTextSingular": {
        "source": "professional",
        "value": "Let's try to do 1 item today"
      }
    },
    "title": {
      "context": "Learning Path",
      "source": "professional",
      "value": "Path"
    },
    "totalVersusAvailable": {
      "source": "professional",
      "value": "<%- completed %> of <%- available %>"
    },
    "unlockWithDailyTraining": {
      "source": "professional",
      "value": "Unlock with daily training"
    }
  },
  "Highcharts": {
    "shortMonths": {
      "apr": {
        "source": "professional",
        "value": "Apr"
      },
      "aug": {
        "source": "professional",
        "value": "Aug"
      },
      "dec": {
        "source": "professional",
        "value": "Dec"
      },
      "feb": {
        "source": "professional",
        "value": "Feb"
      },
      "jan": {
        "source": "professional",
        "value": "Jan"
      },
      "july": {
        "source": "professional",
        "value": "July"
      },
      "june": {
        "source": "professional",
        "value": "June"
      },
      "mar": {
        "source": "professional",
        "value": "Mar"
      },
      "may": {
        "source": "professional",
        "value": "May"
      },
      "nov": {
        "source": "professional",
        "value": "Nov"
      },
      "oct": {
        "source": "professional",
        "value": "Oct"
      },
      "sept": {
        "source": "professional",
        "value": "Sept"
      }
    },
    "weekday": {
      "fri": {
        "source": "professional",
        "value": "Friday"
      },
      "mon": {
        "source": "professional",
        "value": "Monday"
      },
      "sat": {
        "source": "professional",
        "value": "Saturday"
      },
      "sun": {
        "source": "professional",
        "value": "Sunday"
      },
      "thu": {
        "source": "professional",
        "value": "Thursday"
      },
      "tue": {
        "source": "professional",
        "value": "Tuesday"
      },
      "wed": {
        "source": "professional",
        "value": "Wednesday"
      }
    }
  },
  "OAuthTypes": {
    "oauth2": {
      "context": "OAuth Type for setting up button",
      "source": "professional",
      "value": "OAuth 2"
    },
    "openIdConnect": {
      "context": "OAuth Type for setting up button",
      "source": "professional",
      "value": "OpenID Connect"
    }
  },
  "SelfDirectedPaths": {
    "path": {
      "assigned": {
        "source": "professional",
        "value": "Assigned"
      },
      "assignmentOptionsLabel": {
        "source": "professional",
        "value": "View assignment options menu"
      },
      "enroll": {
        "source": "professional",
        "value": "Enroll"
      },
      "enrolled": {
        "source": "professional",
        "value": "Enrolled"
      },
      "enrollmentOptionsLabel": {
        "source": "professional",
        "value": "View enrollment options menu"
      },
      "revokeCheckboxLabel": {
        "source": "professional",
        "value": "Revoke training from this user"
      },
      "revokeTooltip": {
        "source": "professional",
        "value": "When unassigned, this path will be marked as revoked from the user. Record of this action can be found in the Assigned Path Progress export."
      },
      "unassign": {
        "source": "professional",
        "value": "Unassign"
      },
      "unassignModal": {
        "description": {
          "source": "professional",
          "value": "Any Milestone due dates as well as Topic, Task, and Event progress will be preserved."
        },
        "error": {
          "source": "professional",
          "value": "Could not unassign training from this user, an unexpected error occurred."
        },
        "message": {
          "context": "This message appears when attempting to unassign a user from a specific training program. E.g. 'Unassign this from Ladder Safety training?'",
          "source": "professional",
          "value": "Unassign this user from <b><%- programName %></b>?"
        },
        "revokeSuccess": {
          "context": "This message pops up when a specific training program is unassigned and revoked from the user. Ex: You have successfully unassigned and revoked 'Ladder Safety Training' from John Doe.",
          "source": "professional",
          "value": "You have successfully unassigned and revoked '<%- programName %>' from <%- user %>."
        },
        "success": {
          "context": "This message pops up when a user has been unassigned a specific training program. Ex: You have successfully unassigned John Doe from 'Ladder Safety Training'",
          "source": "professional",
          "value": "You have successfully unassigned <%- user %> from '<%- programName %>'"
        },
        "title": {
          "source": "professional",
          "value": "Unassign Training"
        }
      },
      "unenroll": {
        "source": "professional",
        "value": "Unenroll"
      }
    },
    "unenrollModal": {
      "content": {
        "source": "professional",
        "value": "All Topic, Task and Event progress will be preserved."
      },
      "header": {
        "context": "pathName is the name of the path the user is unenrolling from",
        "source": "professional",
        "value": "Unenroll from <%- pathName %>?"
      },
      "milestones": {
        "source": "professional",
        "value": "Any Milestone due dates will reset if you unenroll in this path."
      }
    }
  },
  "UIKit": {
    "Form": {
      "Editor": {
        "AccordionCollection": {
          "header": {
            "itemTitle": {
              "source": "professional",
              "value": "Item"
            },
            "ownerTitle": {
              "source": "professional",
              "value": "Owner"
            }
          },
          "ownedCount": {
            "source": "professional",
            "value": "<%- count %> owned"
          }
        },
        "AxonSelect": {
          "multiSelect": {
            "source": "professional",
            "value": "Select some options"
          },
          "noResults": {
            "source": "professional",
            "value": "No results match"
          }
        },
        "Colour": {
          "chooseText": {
            "source": "professional",
            "value": "Choose"
          }
        },
        "Date": {
          "Month": {
            "context": "Month Selector in Duet Date Picker.",
            "source": "professional",
            "value": "Month"
          },
          "Year": {
            "context": "Year Selector in Duet Date Picker.",
            "source": "professional",
            "value": "Year"
          },
          "placeholder": {
            "source": "professional",
            "value": "mm/dd/yyyy"
          },
          "selectedDate": {
            "context": "Selected date message in Duet Date Picker.",
            "source": "professional",
            "value": "Selected Date"
          }
        },
        "DateTime": {
          "AM": {
            "source": "professional",
            "value": "AM"
          },
          "PM": {
            "source": "professional",
            "value": "PM"
          },
          "change": {
            "source": "professional",
            "value": "Change Date"
          },
          "date": {
            "source": "professional",
            "value": "<%- date %> at <%- time %>"
          },
          "endDate": {
            "source": "professional",
            "value": "Change End Date"
          },
          "now": {
            "source": "professional",
            "value": "Today"
          },
          "placeholder": {
            "source": "professional",
            "value": "mm/dd/yyyy - hh:mm AM"
          },
          "selectMonth": {
            "source": "professional",
            "value": "Select month"
          },
          "selectNextMonth": {
            "source": "professional",
            "value": "Select next month"
          },
          "selectPreviousMonth": {
            "source": "professional",
            "value": "Select previous month"
          },
          "selectYear": {
            "source": "professional",
            "value": "Select year"
          },
          "setDate": {
            "source": "professional",
            "value": "Set Date"
          },
          "setTime": {
            "source": "professional",
            "value": "Set Time"
          }
        },
        "Location": {
          "counting": {
            "source": "professional",
            "value": "<%- count %> Team(s)"
          }
        },
        "MediaThumbnail": {
          "previewUnavailable": {
            "source": "professional",
            "value": "Preview not available"
          }
        },
        "Select": {
          "select": {
            "source": "professional",
            "value": "Select"
          }
        },
        "TranslationStrings": {
          "empty": {
            "source": "professional",
            "value": "No Translations Added yet"
          },
          "original": {
            "source": "professional",
            "value": "Show Original"
          },
          "other": {
            "source": "professional",
            "value": "Other Translations"
          }
        },
        "TreeEditor": {
          "bulk": {
            "departments": {
              "context": "Used as aria-labels for the bulk select button in Quick Filters",
              "source": "professional",
              "value": "Bulk Select Departments"
            },
            "jobTitles": {
              "source": "professional",
              "value": "Bulk Select Job Titles"
            },
            "linesOfBusiness": {
              "source": "professional",
              "value": "Bulk Select Lines of Business"
            },
            "locationTypes": {
              "source": "professional",
              "value": "Bulk Select Team Types"
            },
            "locations": {
              "source": "professional",
              "value": "Bulk Select Teams"
            }
          },
          "buttons": {
            "description": {
              "excluded": {
                "context": "Describes current and next AOI state. (ie. Axonify New Hires Excluded. There are 3 states to cycle through, Included is the next state.)",
                "source": "professional",
                "value": "<%- name %> Excluded. There are 3 states to cycle through, Selected is the next state."
              },
              "included": {
                "context": "Describes current and next AOI state. (ie. Axonify New Hires Included. There are 3 states to cycle through, Required is the next state.)",
                "source": "professional",
                "value": "<%- name %> Selected. There are 3 states to cycle through, Required is the next state."
              },
              "required": {
                "context": "Describes current and next AOI state. (ie. Axonify New Hires Required. There are 3 states to cycle through, Excluded is the next state.",
                "source": "professional",
                "value": "<%- name %> Required. There are 3 states to cycle through, Excluded is the next state."
              }
            },
            "exclude": {
              "departments": {
                "context": "Used as aria-labels for the Exclude button in Quick Filters",
                "source": "professional",
                "value": "Exclude selected Departments"
              },
              "jobTitles": {
                "source": "professional",
                "value": "Exclude selected Job Titles"
              },
              "linesOfBusiness": {
                "source": "professional",
                "value": "Exclude selected Lines of Business"
              },
              "locationTypes": {
                "source": "professional",
                "value": "Exclude selected Team Types"
              },
              "locations": {
                "source": "professional",
                "value": "Exclude selected Teams"
              }
            },
            "include": {
              "departments": {
                "context": "Used as aria-labels for the Include button in Quick Filters",
                "source": "professional",
                "value": "Include selected Departments"
              },
              "jobTitles": {
                "source": "professional",
                "value": "Include selected Job Titles"
              },
              "linesOfBusiness": {
                "source": "professional",
                "value": "Include selected Lines of Business"
              },
              "locationTypes": {
                "source": "professional",
                "value": "Include selected Team Types"
              },
              "locations": {
                "source": "professional",
                "value": "Include selected Teams"
              }
            }
          },
          "exclude": {
            "source": "professional",
            "value": "Exclude"
          },
          "explanation": {
            "header": {
              "source": "professional",
              "value": "Filter Conditions"
            },
            "instructions": {
              "source": "professional",
              "value": "After selecting an AOI, toggle through the condition by clicking the states described."
            },
            "instructions_TeamAttribute": {
              "context": "Instructions on using Team Attributes",
              "source": "professional",
              "value": "After selecting a Team Attribute, toggle through the condition by clicking the states described."
            },
            "state": {
              "allRequired": {
                "description": {
                  "source": "professional",
                  "value": "Users must have all these Areas of Interest assigned to be included in this group."
                },
                "description_TeamAttribute": {
                  "context": "Description of State",
                  "source": "professional",
                  "value": "Users must have all these Team Attributes assigned to be included in this group."
                },
                "header": {
                  "source": "professional",
                  "value": "All required"
                }
              },
              "atLeastOne": {
                "description": {
                  "source": "professional",
                  "value": "Users must have at least one of these Areas of Interest assigned to be included in this group."
                },
                "description_TeamAttribute": {
                  "context": "Description of State",
                  "source": "professional",
                  "value": "Users must have at least one of these Team Attributes assigned to be included in this group."
                },
                "header": {
                  "source": "professional",
                  "value": "At least one"
                }
              },
              "excluded": {
                "description": {
                  "source": "professional",
                  "value": "Users must not have any of these Areas of Interest assigned to be included in this group"
                },
                "description_TeamAttribute": {
                  "context": "Description of State",
                  "source": "professional",
                  "value": "Users must not have any of these Team Attributes assigned to be included in this group"
                },
                "header": {
                  "source": "professional",
                  "value": "Must not include"
                }
              },
              "header": {
                "source": "professional",
                "value": "States:"
              }
            },
            "subText": {
              "source": "professional",
              "value": "How to use"
            }
          },
          "headers": {
            "dateCondition": {
              "source": "professional",
              "value": "Follows this Date Condition"
            },
            "departments": {
              "source": "professional",
              "value": "In one of these departments"
            },
            "departments_mustExclude": {
              "source": "professional",
              "value": "In none of these departments"
            },
            "groupAreasOfInterest": {
              "source": "professional",
              "value": "Has at least one of these Areas of Interest"
            },
            "groupAreasOfInterest_mustExclude": {
              "source": "professional",
              "value": "Has none of these Areas of Interest"
            },
            "groupAreasOfInterest_mustInclude": {
              "source": "professional",
              "value": "Has all of these Areas of Interest"
            },
            "groupTeamAttributes_EXCLUDE": {
              "context": "Filter for group building",
              "source": "professional",
              "value": "Not on a Team with one of these (<%- attribute %>) Attributes"
            },
            "groupTeamAttributes_INCLUDE": {
              "context": "Filter for group building",
              "source": "professional",
              "value": "On a Team with one of these (<%- attribute %>) Attributes"
            },
            "groupTeamAttributes_REQUIRED": {
              "context": "Filter for group building",
              "source": "professional",
              "value": "On a Team with all of these (<%- attribute %>) Attributes"
            },
            "jobTitles": {
              "source": "professional",
              "value": "Has one of these job titles"
            },
            "jobTitles_mustExclude": {
              "source": "professional",
              "value": "Has none of these job titles"
            },
            "linesOfBusiness": {
              "source": "professional",
              "value": "In one of these Lines of Business"
            },
            "linesOfBusiness_mustExclude": {
              "source": "professional",
              "value": "In none of these Lines of Business"
            },
            "locationTypes": {
              "source": "professional",
              "value": "On a Team with one of these team types"
            },
            "locationTypes_mustExclude": {
              "source": "professional",
              "value": "Not on a Team with one of these team types"
            },
            "locations": {
              "source": "professional",
              "value": "On one of these teams"
            },
            "locations_mustExclude": {
              "source": "professional",
              "value": "On none of these teams"
            }
          },
          "help": {
            "source": "professional",
            "value": "Group Characteristics"
          },
          "include": {
            "source": "professional",
            "value": "Include"
          },
          "teamAttributes": {
            "context": "Header Title for Tree Editor",
            "source": "professional",
            "value": "Custom Team Attributes"
          },
          "title": {
            "excluded": {
              "context": "Communicates that an area-of-interest is excluded from the user's filtering selections",
              "source": "professional",
              "value": "Excluded"
            },
            "included": {
              "context": "Communicates that an area-of-interest is included in the user's filtering selections",
              "source": "professional",
              "value": "Selected"
            },
            "required": {
              "context": "Communicates that an area-of-interest is required in the user's filtering selections",
              "source": "professional",
              "value": "Required"
            }
          }
        },
        "richTextEditorPlusBlank": {
          "addABlank": {
            "source": "professional",
            "value": "Add a Blank"
          },
          "addAnswer": {
            "source": "professional",
            "value": "Add Answer"
          }
        }
      },
      "errors": {
        "boundedValue": {
          "source": "professional",
          "value": "Values should between <%- minValue %> and <%- maxValue %>"
        },
        "date": {
          "invalid": {
            "source": "professional",
            "value": "Invalid Date"
          },
          "invalidStartEndDates": {
            "source": "professional",
            "value": "The end date should not be before the start date"
          },
          "mustBeAfter": {
            "source": "professional",
            "value": "The end date cannot be earlier than the start date"
          },
          "mustBeBefore": {
            "source": "professional",
            "value": "The start date cannot be after than the end date"
          }
        },
        "email": {
          "invalid": {
            "source": "professional",
            "value": "Please enter a valid email address."
          },
          "required": {
            "source": "professional",
            "value": "Email is a required field."
          }
        },
        "field": {
          "required": {
            "context": "Example: Username is a required field",
            "source": "professional",
            "value": "<%- label %> is a required field."
          },
          "url": {
            "context": "Provides a message when a URL doesn't validate",
            "source": "professional",
            "value": "You must provide a valid URL that starts with http:// or https://"
          }
        },
        "maxCharacters": {
          "source": "professional",
          "value": "Maximum characters allowed is <%- maxLength %>"
        },
        "maxCharactersNamed": {
          "source": "professional",
          "value": "Maximum characters for <%- context %> is <%- maxLength %>"
        },
        "numericRange": {
          "source": "professional",
          "value": "This field must be between <%- min %> and <%- max %>."
        },
        "password": {
          "invalid": {
            "source": "professional",
            "value": "The password must contain at least 5 characters."
          }
        }
      }
    },
    "messageModal": {
      "emptyMessage": {
        "source": "professional",
        "value": "You must enter a message before sending"
      },
      "placeholder": {
        "source": "professional",
        "value": "Start writing your message here..."
      },
      "title": {
        "source": "professional",
        "value": "Send a Message"
      },
      "to": {
        "source": "professional",
        "value": "Send message to <%- name %>"
      },
      "toAllReviewers": {
        "source": "professional",
        "value": "Send message to reviewers"
      }
    }
  },
  "accessibility": {
    "clickPrompt": {
      "source": "professional",
      "value": "Click for details."
    },
    "inputRemainingCharacters": {
      "context": "an explanation of how many characters remain for text entry",
      "source": "professional",
      "value": "<%- value %> characters remaining"
    },
    "tooltipInstructions": {
      "context": "Instructions read out to the user via a screen reader telling them what to do.",
      "source": "professional",
      "value": "Tooltip. Click to read."
    }
  },
  "activateaccount": {
    "btntitle": {
      "source": "professional",
      "value": "Activate"
    },
    "confirmnewpassword": {
      "source": "professional",
      "value": "Confirm new password"
    },
    "enternewpassword": {
      "source": "professional",
      "value": "Enter a new password"
    },
    "enteroldpassword": {
      "source": "professional",
      "value": "Enter your old password"
    },
    "failedtoactivate": {
      "source": "professional",
      "value": "Failed to activate your account. Please check your credentials."
    },
    "instructions": {
      "source": "professional",
      "value": ""
    },
    "languagepref": {
      "source": "professional",
      "value": "Language preference:"
    },
    "msg": {
      "source": "professional",
      "value": "Your account settings have expired. Please enter new details."
    },
    "newpassword": {
      "source": "professional",
      "value": "New Password:"
    },
    "oldpassword": {
      "source": "professional",
      "value": "Old Password:"
    },
    "passwordagain": {
      "source": "professional",
      "value": "Enter Password Again:"
    },
    "q1": {
      "source": "professional",
      "value": "What was your childhood nickname?"
    },
    "q10": {
      "source": "professional",
      "value": "What is the country of your ultimate dream vacation?"
    },
    "q11": {
      "source": "professional",
      "value": "What was your dream job as a child?"
    },
    "q12": {
      "source": "professional",
      "value": "What is the street number of the house you grew up in?"
    },
    "q13": {
      "source": "professional",
      "value": "Who was your childhood hero?"
    },
    "q14": {
      "source": "professional",
      "value": "What was the first concert you attended?"
    },
    "q15": {
      "source": "professional",
      "value": "What was the make and model of your first car?"
    },
    "q2": {
      "source": "professional",
      "value": "What is the name of your favorite childhood friend?"
    },
    "q3": {
      "source": "professional",
      "value": "In what city did you meet your spouse/significant other?"
    },
    "q4": {
      "source": "professional",
      "value": "In what city or town did your mother and father meet?"
    },
    "q5": {
      "source": "professional",
      "value": "Who do you admire the most?"
    },
    "q6": {
      "source": "professional",
      "value": "What was the name of your first stuffed animal?"
    },
    "q7": {
      "source": "professional",
      "value": "What was the last name of your third grade teacher?"
    },
    "q8": {
      "source": "professional",
      "value": "In what city or town was your first job?"
    },
    "q9": {
      "source": "professional",
      "value": "What was your favorite place to visit as a child?"
    },
    "securityPlaceholder": {
      "source": "professional",
      "value": "Enter your security answer..."
    },
    "securityanswer": {
      "source": "professional",
      "value": "Security Answer <%- i %>:"
    },
    "securityquestion": {
      "source": "professional",
      "value": "Security Question <%- i %>:"
    },
    "selectaois": {
      "source": "professional",
      "value": "Select all areas of interest that apply to you:"
    },
    "title": {
      "source": "professional",
      "value": "Activate Your Account"
    },
    "updatepassword": {
      "source": "professional",
      "value": "Update Password"
    },
    "updatesecurityquestion": {
      "source": "professional",
      "value": "Update Security Questions"
    }
  },
  "assessments": {
    "item": {
      "action": {
        "fail": {
          "source": "professional",
          "value": "Re-Take"
        },
        "resume": {
          "source": "professional",
          "value": "Resume"
        },
        "resumeAriaLabel": {
          "context": "Aria label for Resume button on the daily training card. Ex: Resume Axonify Experts program.",
          "source": "professional",
          "value": "Resume <%- programName %> program."
        }
      },
      "content": {
        "questions": {
          "plural": {
            "source": "professional",
            "value": "<%- count %> questions"
          },
          "pluralWithTraining": {
            "source": "professional",
            "value": "<%- trainingStr %> & <%- count %> questions"
          },
          "singular": {
            "source": "professional",
            "value": "1 question"
          },
          "singularWithTraining": {
            "source": "professional",
            "value": "<%- trainingStr %> & 1 question"
          }
        },
        "training": {
          "VideoFile": {
            "source": "professional",
            "value": "1 video"
          },
          "VideoUrl": {
            "source": "professional",
            "value": "1 video"
          },
          "externalLink": {
            "source": "professional",
            "value": "1 module"
          },
          "module": {
            "plural": {
              "context": "Number of modules to complete (i.e. 2 Modules)",
              "source": "professional",
              "value": "<%- number %> modules"
            }
          },
          "pdf": {
            "source": "professional",
            "value": "1 module"
          },
          "scorm": {
            "source": "professional",
            "value": "1 module"
          },
          "tincan": {
            "source": "professional",
            "value": "1 module"
          },
          "video": {
            "plural": {
              "context": "Number of videos to watch (i.e. 2 Videos)",
              "source": "professional",
              "value": "<%- number %> videos"
            }
          }
        }
      },
      "dueBy": {
        "plural": {
          "source": "professional",
          "value": "Due in <%- daysLeft %> days"
        },
        "singular": {
          "source": "professional",
          "value": "Due in 1 day"
        },
        "today": {
          "source": "professional",
          "value": "Due today"
        }
      },
      "event": {
        "attendanceNotTaken": {
          "source": "professional",
          "value": "Attendance Not Taken"
        },
        "attended": {
          "source": "professional",
          "value": "Attended: <%- date %>"
        },
        "enrolled": {
          "source": "professional",
          "value": "Enrolled"
        },
        "enrolledWithDate": {
          "context": "Date that event training will occur, e.g. Enrolled: September 7, 2020",
          "source": "professional",
          "value": "Enrolled: <%- date %>"
        },
        "managerEnrollmentRequired": {
          "source": "professional",
          "value": "Talk to your manager to enroll you in this event."
        },
        "noScheduledEvents": {
          "source": "professional",
          "value": "No sessions available. Check again later."
        },
        "onWaitList": {
          "source": "professional",
          "value": "On Waitlist"
        },
        "scheduledEvents": {
          "plural": {
            "context": "Number of sessions available, plural",
            "source": "professional",
            "value": "<%- count %> sessions available"
          },
          "singular": {
            "source": "professional",
            "value": "1 session available"
          }
        }
      },
      "label": {
        "cannotBegin": {
          "context": "Outlines the type and name of the selected assessment which cannot be started.",
          "source": "professional",
          "value": "The <%- assessmentType %> with the name <%- assessmentName %> cannot be started."
        },
        "locked": {
          "context": "Outlines the type and name of assessment selected, and the prerequisite topic for a locked assessment",
          "source": "professional",
          "value": "The <%- assessmentType %> with the name <%- assessmentName %> is currently locked. Pass <%- prereqTopic %> to unlock."
        },
        "notCompleted": {
          "context": "Outlines the type and name of the selected assessment which is not completed.",
          "source": "professional",
          "value": "The <%- assessmentType %> with the name <%- assessmentName %> is currently not completed."
        },
        "notPassed": {
          "context": "Outlines the type, name, previous score, and passing grade of the selected assessment which has been failed.",
          "source": "professional",
          "value": "The <%- assessmentType %> with the name <%- assessmentName %> is not passed, with a previous score of <%- score %>%. <%- passingGrade %>% is needed to pass."
        },
        "passed": {
          "context": "Outlines the type, name, and previous score of the selected assessment which has been passed.",
          "source": "professional",
          "value": "The <%- assessmentType %> with the name <%- assessmentName %> has been passed, with a previous score of <%- score %>%."
        }
      },
      "overDue": {
        "source": "professional",
        "value": "Overdue!"
      },
      "rewardPoints": {
        "assessmentEarned": {
          "context": "Shown on completion of assessment, e.g. +16 points earned",
          "source": "professional",
          "value": "+<%- points %> points earned"
        },
        "assessmentEarned_short": {
          "context": "Shown on completion of assessment, e.g. +16 points",
          "source": "professional",
          "value": "+<%- points %> points"
        },
        "earned": {
          "source": "professional",
          "value": "<%- points %> reward points earned!"
        },
        "earnedSoFar": {
          "source": "professional",
          "value": "<%- points %> of <%- total %> reward points earned!"
        },
        "possible": {
          "source": "professional",
          "value": "<%- points %> reward points"
        },
        "singular": {
          "source": "professional",
          "value": "1 reward point"
        },
        "totalPointsEarnable": {
          "source": "professional",
          "value": "Up to <%- total %> reward points"
        }
      },
      "status": {
        "notPassed": {
          "context": "Not Passed - 20%, needed 80% to pass",
          "source": "professional",
          "value": "Not Passed - <%- grade %>%, needed <%- passingGrade %>% to pass"
        },
        "notPassed_short": {
          "context": "Not Passed - 20%",
          "source": "professional",
          "value": "Not Passed - <%- grade %>%"
        },
        "passed": {
          "context": "Passed - 70%",
          "source": "professional",
          "value": "Passed - <%- grade %>%"
        }
      },
      "title": {
        "context": "Banana Handling - Level 2",
        "source": "professional",
        "value": "<%- assessmentName %> - Level <%- level %>"
      }
    },
    "progress": {
      "label": {
        "source": "professional",
        "value": "Progress"
      },
      "segment": {
        "module": {
          "plural": {
            "context": "indicates the number of modules that will be shown",
            "source": "professional",
            "value": "<%- count %> modules"
          },
          "singular": {
            "source": "professional",
            "value": "1 module"
          }
        },
        "questions": {
          "plural": {
            "source": "professional",
            "value": "<%- count %> questions"
          },
          "singular": {
            "source": "professional",
            "value": "1 question"
          }
        },
        "video": {
          "plural": {
            "context": "indicates the number of videos that will be shown",
            "source": "professional",
            "value": "<%- count %> videos"
          },
          "singular": {
            "source": "professional",
            "value": "1 video"
          }
        }
      }
    },
    "select": {
      "back": {
        "source": "professional",
        "value": "Back to list"
      }
    },
    "types": {
      "CertificationTraining": {
        "source": "professional",
        "value": "Certification"
      },
      "ExtraTrainingModule": {
        "source": "professional",
        "value": "Extra Training Module"
      },
      "ExtraTrainingQuestions": {
        "source": "professional",
        "value": "Extra Training Questions"
      },
      "FormalExamTraining": {
        "source": "professional",
        "value": "Scheduled Exam"
      },
      "IntroductoryTraining": {
        "source": "professional",
        "value": "Introductory Training"
      },
      "RefresherTraining": {
        "source": "professional",
        "value": "Refresher"
      },
      "SelfDirected": {
        "source": "professional",
        "value": "Paths"
      }
    }
  },
  "attachments": {
    "download": {
      "source": "professional",
      "value": "Download file <%- name %>"
    },
    "preview": {
      "source": "professional",
      "value": "Preview file <%- name %>"
    }
  },
  "behaviours": {
    "labels": {
      "evaluator": {
        "source": "professional",
        "value": "Evaluator"
      },
      "observer": {
        "context": "This is the column header for the observer (only) value in the Team Behaviour Forms list",
        "source": "professional",
        "value": "Observer"
      }
    },
    "templates": {
      "evaluation": {
        "body": {
          "source": "professional",
          "value": "An evaluation form is a scored, point-in-time assessment of an employee's behaviors. Evaluators indicate whether or not a behavior was demonstrated. All items in the form must be answered by the evaluator."
        },
        "evaluationType": {
          "inPerson": {
            "context": "This is the display name of the default In-person Evaluation type behaviour form template.",
            "source": "professional",
            "value": "In-person"
          },
          "virtual": {
            "context": "This is the display name of the Virtual Evaluation type behaviour form template.",
            "source": "professional",
            "value": "Virtual"
          }
        }
      },
      "header": {
        "source": "professional",
        "value": "Behavior Form Templates"
      },
      "observation": {
        "body": {
          "source": "professional",
          "value": "An observation form captures data about an employee's behaviors over time. Observers indicate how often a behavior was or was not demonstrated."
        }
      }
    }
  },
  "carousel": {
    "nextSlide": {
      "source": "professional",
      "value": "Next Slide"
    },
    "prevSlide": {
      "source": "professional",
      "value": "Previous Slide"
    }
  },
  "coaches": {
    "description": {
      "coach1": {
        "source": "professional",
        "value": "Illustrated coach with short black hair and a light skin tone wearing a blue polo shirt."
      },
      "coach10": {
        "source": "professional",
        "value": "Illustrated coach with blond hair in a ponytail and a light skin tone wearing a headset, a green dress shirt, dark blue pants and red shoes."
      },
      "coach11": {
        "source": "professional",
        "value": "Illustrated coach with short green hair and a light skin tone wearing a light blue dress shirt, dark blue blazer, black pants and brown shoes."
      },
      "coach12": {
        "source": "professional",
        "value": "Illustrated coach with short green hair and a medium-light skin tone wearing a light blue dress shirt, dark blue blazer, black pants and brown shoes."
      },
      "coach13": {
        "source": "professional",
        "value": "Illustrated coach with short green hair and a medium-dark skin tone wearing a light blue dress shirt, dark blue blazer, black pants and brown shoes."
      },
      "coach14": {
        "source": "professional",
        "value": "Illustrated coach with blond hair in a ponytail and a light skin tone wearing a green dress shirt, dark blue pants and red shoes."
      },
      "coach15": {
        "source": "professional",
        "value": "Illustrated coach with long black hair with bangs, and a light skin tone wearing a red hairband, glasses, an orange shirt, light blue pants and brown shoes holding a handheld scanner."
      },
      "coach16": {
        "source": "professional",
        "value": "Illustrated coach with short blond hair and a light skin tone wearing a light blue dress shirt, khaki pants, and brown shoes holding a laptop."
      },
      "coach17": {
        "source": "professional",
        "value": "Illustrated coach with short brown hair and a light skin tone wearing a light blue dress shirt, khaki pants, and brown shoes holding a laptop."
      },
      "coach18": {
        "source": "professional",
        "value": "Illustrated coach with short dark brown hair and a light skin tone wearing a light blue dress shirt, khaki pants, and brown shoes."
      },
      "coach19": {
        "source": "professional",
        "value": "Illustrated coach with chin-length purple hair and a dark skin tone wearing earrings, a black shirt, a blue belt, dull purple pants and blue shoes holding a briefcase."
      },
      "coach2": {
        "source": "professional",
        "value": "Illustrated coach with short black hair, a short beard, and a medium-dark skin tone wearing a red polo shirt."
      },
      "coach20": {
        "source": "professional",
        "value": "Illustrated coach with short gray hair and a medium-dark skin tone wearing a white polo shirt, dull purple pants, and dark blue shoes."
      },
      "coach21": {
        "source": "professional",
        "value": "Illustrated coach with short gray hair and a medium-dark skin tone wearing a white polo shirt, a white lab coat, gray pants, and dark blue shoes."
      },
      "coach22": {
        "source": "professional",
        "value": "Illustrated coach with short gray hair and a medium-dark skin tone wearing a white polo shirt, a blue apron, gray pants, and dark blue shoes."
      },
      "coach23": {
        "source": "professional",
        "value": "Illustrated coach with long brown hair and a medium-light skin tone wearing a vivid brown sari and red shoes holding a mobile phone."
      },
      "coach24": {
        "source": "professional",
        "value": "Illustrated coach with long brown hair and a medium-light skin tone wearing a vivid brown dress and red shoes."
      },
      "coach25": {
        "source": "professional",
        "value": "Illustrated coach with short brown hair and a medium-light skin tone wearing glasses, a black Axonify shirt, blue pants and brown shoes holding a coffee cup."
      },
      "coach26": {
        "source": "professional",
        "value": "Illustrated coach with short light brown hair and a light skin tone wearing glasses, a black Axonify shirt, blue pants and brown shoes."
      },
      "coach27": {
        "source": "professional",
        "value": "Illustrated coach with short black hair and a dark skin tone wearing glasses, a black Axonify shirt, blue pants and brown shoes."
      },
      "coach28": {
        "source": "professional",
        "value": "Illustrated coach with blue hair in a low bun and a light skin tone wearing a hardhat, a pink shirt, blue overalls and brown boots holding a clipboard."
      },
      "coach29": {
        "source": "professional",
        "value": "Illustrated coach with short brown hair and a light skin tone wearing a hardhat, a black shirt, a yellow safety vest, yellow safety gloves, dark blue pants and blue and purple shoes."
      },
      "coach3": {
        "source": "professional",
        "value": "Illustrated coach with shoulder-length black hair and a light skin tone wearing a purple dress-shirt."
      },
      "coach30": {
        "source": "professional",
        "value": "Illustrated coach with chin-length blue hair and a light skin tone wearing a dark blue dress shirt, light brown pants and white shoes."
      },
      "coach31": {
        "source": "professional",
        "value": "Illustrated coach with shoulder-length magenta hair and a medium-light skin tone wearing a white shirt, dark blue jacket, a maroon skirt and maroon shoes holding a folder."
      },
      "coach32": {
        "source": "professional",
        "value": "Illustrated plus-size coach with long black hair and a dark skin tone wearing a light red headband, light red shirt, dark blue pants and green shoes."
      },
      "coach33": {
        "source": "professional",
        "value": "Illustrated plus-size coach with short brown hair and a medium-light skin tone wearing a light red dress shirt, gray pants, and dark-gray shoes."
      },
      "coach34": {
        "source": "professional",
        "value": "Illustrated coach with long brown hair and a light skin tone wearing glasses, a vivid brown dress shirt, blue pants, and white shoes sitting in a wheelchair."
      },
      "coach35": {
        "source": "professional",
        "value": "Illustrated coach with black hair, a short beard, and a dark skin tone wearing a red shirt, blue pants and gray shoes sitting in a wheelchair."
      },
      "coach4": {
        "source": "professional",
        "value": "Illustrated coach with short black hair, bangs, and a medium-dark skin tone wearing a pink dress-shirt and earrings"
      },
      "coach5": {
        "source": "professional",
        "value": "Illustrated  cartoon coach with short green hair and a light skin tone wearing a white Axonify shirt, dark blue pants, and brown shoes."
      },
      "coach6": {
        "source": "professional",
        "value": "Illustrated cartoon coach with shoulder-length blue hair and a light skin tone wearing earrings, a white Axonify shirt, green pants, and brown shoes."
      },
      "coach7": {
        "source": "professional",
        "value": "Illustrated cartoon coach with short black hair and a dark skin tone wearing a white Axonify shirt, green pants, and red shoes."
      },
      "coach8": {
        "source": "professional",
        "value": "Illustrated cartoon coach with chin-length curly hair and a medium-dark skin tone wearing a white Axonify shirt, earrings, dark blue pants, and purple shoes."
      },
      "coach9": {
        "source": "professional",
        "value": "Illustrated coach with short green hair and a light skin tone wearing a headset, a light blue dress shirt, a dark blue blazer, black pants, and brown shoes."
      },
      "customCoach": {
        "source": "professional",
        "value": "Custom coach uploaded by an administrator"
      },
      "noCoach": {
        "source": "professional",
        "value": "No Coach"
      }
    },
    "thinking": {
      "source": "professional",
      "value": "Coach thinking"
    }
  },
  "communications": {
    "type": {
      "ANNOUNCEMENT": {
        "source": "professional",
        "value": "Announcement"
      },
      "POLL": {
        "source": "professional",
        "value": "Poll"
      },
      "QUIZ": {
        "source": "professional",
        "value": "Quiz"
      }
    }
  },
  "communities": {
    "added": {
      "source": "professional",
      "value": "Community <%- communityName %> has been added successfully"
    },
    "apiKeys": {
      "apiKey": {
        "source": "professional",
        "value": "API Key"
      },
      "createApiKey": {
        "source": "professional",
        "value": "Create API Key"
      },
      "created": {
        "source": "professional",
        "value": "Successfully created API key."
      },
      "defaultOwner": {
        "source": "professional",
        "value": "Default Owner"
      },
      "deleted": {
        "source": "professional",
        "value": "Successfully deleted API key."
      },
      "duplicate": {
        "source": "professional",
        "value": "API key already exists for this user in this community."
      },
      "dzApiKey": {
        "source": "professional",
        "value": "<%= t('appNames.knowledge') %> API Key"
      },
      "dzApiKeys": {
        "source": "professional",
        "value": "<%= t('appNames.knowledge') %> API Keys"
      },
      "emptyName": {
        "source": "professional",
        "value": "Display name must contain text."
      },
      "integration": {
        "source": "professional",
        "value": "Integration"
      },
      "searchUser": {
        "source": "professional",
        "value": "Search User"
      },
      "specifyUser": {
        "source": "professional",
        "value": "Please specify a user for the API key."
      },
      "updated": {
        "source": "professional",
        "value": "Successfully updated API key."
      },
      "warning": {
        "source": "professional",
        "value": "(User must be a moderator of the community.)"
      }
    },
    "layout": {
      "content": {
        "access": {
          "everyone": {
            "source": "professional",
            "value": "Everyone"
          }
        },
        "translations": {
          "default": {
            "source": "professional",
            "value": "Display Name"
          },
          "title": {
            "source": "professional",
            "value": "Translated Versions"
          }
        }
      }
    },
    "saved": {
      "source": "professional",
      "value": "Your community has been saved. Changes may take up to 1 minute to be reflected in the community"
    },
    "unsafeContentRemoved": {
      "source": "professional",
      "value": "Unsafe content was removed from the community name or description before saving."
    }
  },
  "components": {
    "communityPicker": {
      "aria": {
        "numMembers": {
          "source": "professional",
          "value": "Number of members in this community:"
        },
        "showDropdown": {
          "source": "professional",
          "value": "Click to show dropdown; select items to fill in the input."
        }
      },
      "heading": {
        "allCommunities": {
          "source": "professional",
          "value": "All Communities"
        },
        "recentCommunities": {
          "source": "professional",
          "value": "Recent Communities"
        }
      },
      "noResults": {
        "source": "professional",
        "value": "No Communities found. Try adjusting your search."
      },
      "placeholderText": {
        "source": "professional",
        "value": "Select a Community..."
      },
      "selectionsRemoved": {
        "source": "professional",
        "value": "One or more community selections were removed due to a change in filter settings."
      }
    }
  },
  "content": {
    "externalLink": {
      "descriptionLabel": {
        "source": "professional",
        "value": "Module Description"
      }
    },
    "module": {
      "previewUnavailable": {
        "source": "professional",
        "value": "There is no module / preview available for this selected language."
      }
    },
    "modulePreview": {
      "completed": {
        "source": "professional",
        "value": "You have completed the Scorm Training Module"
      }
    }
  },
  "datePicker": {
    "today": {
      "context": "Shown in the Date Picker component when the date is today.",
      "source": "professional",
      "value": "Today"
    },
    "todayWithTime": {
      "context": "Shown in the Date Time Picker component when the date is today.",
      "source": "professional",
      "value": "Today at <%- time %>"
    }
  },
  "dateRangeSelector": {
    "label": {
      "nextMonth": {
        "source": "professional",
        "value": "Next month"
      },
      "nextWeek": {
        "source": "professional",
        "value": "Next week"
      },
      "previousMonth": {
        "source": "professional",
        "value": "Previous month"
      },
      "previousWeek": {
        "source": "professional",
        "value": "Previous week"
      },
      "resetDateFilter": {
        "source": "professional",
        "value": "Reset date filter"
      }
    }
  },
  "discover": {
    "access": {
      "error": {
        "3064": {
          "source": "professional",
          "value": "Community Name and Description must not include HTML tags. Please remove any HTML tags from these fields."
        }
      }
    },
    "browse": {
      "activePageCount": {
        "articles": {
          "multiple": {
            "context": "activePageCount is an integer, to show how many articles are in a community",
            "source": "professional",
            "value": "<%- activePageCount %> Articles"
          },
          "none": {
            "context": "activePageCount in this case will be zero, letting the user that there are no active articles in this community",
            "source": "professional",
            "value": "0 Articles"
          },
          "singular": {
            "context": "activePageCount in this case will be 1 (one), letting the user know there is only 1 article in this community",
            "source": "professional",
            "value": "1 Article"
          }
        },
        "list": {
          "context": "Combined phrase, when there are both articles and posts, for example: 12 Articles, 3 Posts",
          "source": "professional",
          "value": "<%- articleCount %>, <%- postCount %>"
        },
        "posts": {
          "multiple": {
            "context": "enumerates the number of posts in a community",
            "source": "professional",
            "value": "<%- activePageCount %> Posts"
          },
          "none": {
            "context": "enumerates the number of posts in a community when that number is zero",
            "source": "professional",
            "value": "0 Posts"
          },
          "singular": {
            "context": "enumerates the number of posts in a community when that number is one",
            "source": "professional",
            "value": "1 Post"
          }
        }
      },
      "add": {
        "source": "professional",
        "value": "Add"
      },
      "addEntryMenu": {
        "context": "This is a button the opens a dropdown menu so a user can select from a list of options when creating a new page/entry",
        "source": "professional",
        "value": "Add New Page Menu Dropdown"
      },
      "allNotices": {
        "source": "professional",
        "value": "All Notices"
      },
      "communities": {
        "source": "professional",
        "value": "Communities"
      },
      "communitiesManagement": {
        "source": "professional",
        "value": "Communities Management"
      },
      "communityDescription": {
        "seeMore": {
          "source": "professional",
          "value": "See more"
        }
      },
      "communitySettings": {
        "context": "Article context menu item",
        "source": "professional",
        "value": "Community Settings"
      },
      "contextMenuDropdown": {
        "source": "professional",
        "value": "Reports and Community Settings dropdown"
      },
      "create": {
        "article": {
          "context": "This is a dropdown option for creating new Articles in Discover",
          "source": "professional",
          "value": "Article"
        },
        "question": {
          "context": "This is a dropdown option for creating new Questions in Discover",
          "source": "professional",
          "value": "Question"
        },
        "reference": {
          "context": "This is a dropdown option for creating new Links in Discover",
          "source": "professional",
          "value": "Link"
        }
      },
      "favorites": {
        "source": "professional",
        "value": "Favorites"
      },
      "insights": {
        "title": {
          "source": "professional",
          "value": "Communications Insights"
        }
      },
      "needsReview": {
        "source": "professional",
        "value": "Needs Review"
      },
      "pending": {
        "context": "A count of articles that are pending approval (i.e. '5 Pending')",
        "source": "professional",
        "value": "<%- numPending %> Pending"
      },
      "reportedCount": {
        "context": "A count of articles that have been reported (i.e. '5 Reported')",
        "source": "professional",
        "value": "<%- numReported %> Reported"
      },
      "reportedItems": {
        "source": "professional",
        "value": "Reported"
      },
      "reports": {
        "chart": {
          "noData": {
            "source": "professional",
            "value": "There is no data to display."
          }
        },
        "filterByDateLabel": {
          "source": "professional",
          "value": "Filter by date"
        },
        "pages": {
          "PAGES_BY_STATUS": {
            "series": {
              "article": {
                "expired": {
                  "source": "professional",
                  "value": "Articles Expired"
                },
                "fact": {
                  "source": "professional",
                  "value": "Articles Approved"
                },
                "quarantine": {
                  "source": "professional",
                  "value": "Articles Reported"
                },
                "review": {
                  "source": "professional",
                  "value": "Articles Unreviewed"
                },
                "scheduled": {
                  "source": "professional",
                  "value": "Articles Scheduled"
                }
              },
              "description": {
                "context": "Description for page by status pie chart. No need to translate the word 'Discover'",
                "source": "professional",
                "value": "This chart shows the number of Discover article pages by status."
              },
              "post": {
                "fact": {
                  "source": "professional",
                  "value": "Posts Created"
                }
              },
              "question": {
                "fact": {
                  "source": "professional",
                  "value": "Questions Answered"
                },
                "quarantine": {
                  "source": "professional",
                  "value": "Questions Reported"
                },
                "review": {
                  "source": "professional",
                  "value": "Unanswered Questions"
                }
              },
              "reference": {
                "fact": {
                  "source": "professional",
                  "value": "Links Approved"
                },
                "quarantine": {
                  "source": "professional",
                  "value": "Links Reported"
                },
                "review": {
                  "source": "professional",
                  "value": "Links Unreviewed"
                }
              },
              "title": {
                "source": "professional",
                "value": "Item Status"
              },
              "training": {
                "fact": {
                  "source": "professional",
                  "value": "Training Articles Approved"
                },
                "quarantine": {
                  "source": "professional",
                  "value": "Training Articles Reported"
                },
                "review": {
                  "source": "professional",
                  "value": "Training Articles Unreviewed"
                }
              }
            },
            "title": {
              "source": "professional",
              "value": "Pages by Status"
            }
          },
          "TOP_KEYWORDS_IN_SEARCH": {
            "table": {
              "count": {
                "source": "professional",
                "value": "Count"
              },
              "name": {
                "source": "professional",
                "value": "Keywords"
              }
            },
            "title": {
              "source": "professional",
              "value": "Top Keywords Used in Search"
            }
          },
          "TOP_KEYWORDS_IN_SEARCH_EMPTY": {
            "table": {
              "count": {
                "source": "professional",
                "value": "Count"
              },
              "name": {
                "source": "professional",
                "value": "Keywords"
              }
            },
            "title": {
              "source": "professional",
              "value": "Top Keywords Used in Search - Empty Results"
            }
          },
          "TOP_PAGES": {
            "table": {
              "count": {
                "source": "professional",
                "value": "Views"
              },
              "name": {
                "source": "professional",
                "value": "Top Pages"
              }
            },
            "title": {
              "source": "professional",
              "value": "Top Pages"
            }
          }
        },
        "title": {
          "source": "professional",
          "value": "Communities Reports"
        }
      },
      "timePeriod": {
        "allTime": {
          "source": "professional",
          "value": "All Time"
        },
        "months": {
          "10": {
            "source": "professional",
            "value": "October"
          },
          "11": {
            "source": "professional",
            "value": "November"
          },
          "12": {
            "source": "professional",
            "value": "December"
          },
          "01": {
            "source": "professional",
            "value": "January"
          },
          "02": {
            "source": "professional",
            "value": "February"
          },
          "03": {
            "source": "professional",
            "value": "March"
          },
          "04": {
            "source": "professional",
            "value": "April"
          },
          "05": {
            "source": "professional",
            "value": "May"
          },
          "06": {
            "source": "professional",
            "value": "June"
          },
          "07": {
            "source": "professional",
            "value": "July"
          },
          "08": {
            "source": "professional",
            "value": "August"
          },
          "09": {
            "source": "professional",
            "value": "September"
          }
        }
      }
    },
    "dzArticleTypes": {
      "module": {
        "source": "professional",
        "value": "Module"
      }
    },
    "errors": {
      "Article": {
        "title": {
          "length": {
            "source": "professional",
            "value": "The value specified for title is too long. Maximum is <%- maxTitleLength %> characters."
          }
        }
      },
      "Question": {
        "title": {
          "length": {
            "source": "professional",
            "value": "The value specified for title is too long. Maximum is <%- maxTitleLength %> characters."
          }
        }
      },
      "Reference": {
        "title": {
          "length": {
            "source": "professional",
            "value": "The value specified for title is too long. Maximum is <%- maxTitleLength %> characters."
          }
        },
        "url": {
          "context": "Provides a message when a URL doesn't validate",
          "source": "professional",
          "value": "You must provide a valid URL that starts with http:// or https://"
        }
      }
    },
    "insights": {
      "howItWorks": {
        "title": {
          "source": "professional",
          "value": "How it Works"
        }
      },
      "title": {
        "source": "professional",
        "value": "Insights"
      }
    },
    "metadata": {
      "approved": {
        "source": "professional",
        "value": "Approved"
      },
      "attachments": {
        "source": "professional",
        "value": "Attachments"
      },
      "author": {
        "source": "professional",
        "value": "Author"
      },
      "comments": {
        "subtitle": {
          "source": "professional",
          "value": "Enable Comments"
        }
      },
      "community": {
        "source": "professional",
        "value": "Home Community"
      },
      "contributors": {
        "source": "professional",
        "value": "Contributors"
      },
      "created": {
        "source": "professional",
        "value": "Created"
      },
      "description": {
        "none": {
          "source": "professional",
          "value": "N/A"
        },
        "title": {
          "source": "professional",
          "value": "Description"
        }
      },
      "documents": {
        "deleteFile": {
          "source": "professional",
          "value": "Delete file <%- name %>"
        },
        "upload": {
          "source": "professional",
          "value": "Attach Files"
        }
      },
      "download": {
        "source": "professional",
        "value": "Download"
      },
      "editNote": {
        "source": "professional",
        "value": "Editor Notes"
      },
      "editPlaceHolder": {
        "source": "professional",
        "value": "Add a note about your edits..."
      },
      "managedBy": {
        "source": "professional",
        "value": "Managed By"
      },
      "modified": {
        "source": "professional",
        "value": "Modified"
      },
      "posts": {
        "subtitle": {
          "source": "professional",
          "value": "Enable Posts"
        }
      },
      "publishDate": {
        "source": "professional",
        "value": "Publish Date"
      },
      "publishOnApproval": {
        "source": "professional",
        "value": "On Approval"
      },
      "publishOnDate": {
        "source": "professional",
        "value": "Scheduled Date"
      },
      "publishSettings": {
        "source": "professional",
        "value": "Publish Settings"
      },
      "recommendations": {
        "description": {
          "source": "professional",
          "value": "Allow this page to be recommended to users."
        },
        "subtitle": {
          "source": "professional",
          "value": "Enable Recommendations"
        }
      },
      "sharedCommunities": {
        "source": "professional",
        "value": "Shared Communities"
      },
      "showHistory": {
        "source": "professional",
        "value": "Show History"
      },
      "skipReview": {
        "source": "professional",
        "value": "Skip Review"
      },
      "subject": {
        "source": "professional",
        "value": "Subject"
      },
      "systemManaged": {
        "source": "professional",
        "value": "System Managed"
      },
      "tag": {
        "label": {
          "source": "professional",
          "value": "Tags"
        },
        "placeholder": {
          "source": "professional",
          "value": "Add a new tag"
        }
      },
      "timeZone": {
        "source": "professional",
        "value": "Time Zone"
      },
      "topic": {
        "source": "professional",
        "value": "Topic"
      },
      "updated": {
        "source": "professional",
        "value": "Updated"
      },
      "updatestring": {
        "context": "clarify date field in expiry notes > articles",
        "source": "professional",
        "value": "Last Updated: <%- date %>"
      }
    },
    "pageAccess": {
      "error": {
        "3017": {
          "source": "professional",
          "value": "The access rules have changed and you can no longer edit this page. Sorry!"
        }
      }
    },
    "pageTypes": {
      "article": {
        "actionText": {
          "agree": {
            "source": "professional",
            "value": "Approve"
          },
          "delete": {
            "action": {
              "source": "professional",
              "value": "Delete Article"
            },
            "confirm": {
              "source": "professional",
              "value": "Are you sure you want to delete this Article"
            }
          },
          "edit": {
            "source": "professional",
            "value": "Edit Article"
          },
          "locked": {
            "source": "professional",
            "value": "Locked for edit by: <%= lockedBy %>"
          },
          "print": {
            "source": "professional",
            "value": "Print Article"
          },
          "report": {
            "source": "professional",
            "value": "Report Article"
          }
        },
        "delete": {
          "success": {
            "source": "professional",
            "value": "\"<%- title %>\" article deleted!"
          }
        },
        "error": {
          "3001": {
            "source": "professional",
            "value": "Changes to this article cannot be saved."
          },
          "3059": {
            "source": "professional",
            "value": "Unfortunately this article is locked for editing by someone else. Please check back in a bit."
          }
        },
        "report": {
          "success": {
            "source": "professional",
            "value": "\"<%- title %>\" article reported!"
          }
        },
        "save": {
          "success": {
            "source": "professional",
            "value": "\"<%- title %>\" article saved!"
          }
        },
        "title": {
          "placeholder": {
            "source": "professional",
            "value": "Article Title"
          }
        }
      },
      "fetch": {
        "error": {
          "source": "professional",
          "value": "This page doesn't exist anymore!"
        }
      },
      "generic": {
        "2008": {
          "source": "professional",
          "value": "The value specified for title or tag is too long."
        },
        "3075": {
          "source": "professional",
          "value": "Some values were rejected by the server. Please check all fields are correct and try again."
        },
        "agreeCountOfTotal": {
          "source": "professional",
          "value": "<%= agreeCount %> of <%= total %>"
        },
        "agreesNeeded": {
          "source": "professional",
          "value": "Needs <strong><%= remainingAgrees %></strong> approval(s) to publish"
        },
        "banners": {
          "multilingual": {
            "expired": {
              "context": "For an article, displays the number of language variants that are expired",
              "source": "professional",
              "value": "<strong><%= expiredCount %></strong> Languages Expired"
            },
            "inReview": {
              "context": "For an article, displays the number of language variants that are in review",
              "source": "professional",
              "value": "<strong><%= inReviewCount %></strong> Languages In Review"
            },
            "reported": {
              "context": "For an article, displays the number of language variants that are reported",
              "source": "professional",
              "value": "<strong><%= reportedCount %></strong> Languages Reported"
            },
            "scheduled": {
              "context": "For an article, displays the number of language variants that are scheduled for publishing at a later date",
              "source": "professional",
              "value": "<strong><%= scheduledCount %></strong> Languages Scheduled"
            }
          },
          "multipleNotices": {
            "context": "When an article has concerns of more than one type, instead of showing multiple banners we show this one which implies that there are multiple problems, thus multiple notices.",
            "source": "professional",
            "value": "<strong><%= noticesCount %></strong> Notices on this Article"
          }
        },
        "characterCount": {
          "source": "professional",
          "value": "Characters remaining: "
        },
        "error": {
          "3017": {
            "source": "professional",
            "value": "The access rules have changed and you can no longer edit this page. Sorry!"
          },
          "3134": {
            "source": "professional",
            "value": "Selected language is not supported!"
          }
        },
        "expiryDate": {
          "context": "Displays the date on which an article expired, and became no longer visible for reading",
          "source": "professional",
          "value": "Expired on <strong><%= expiryDate %></strong>"
        },
        "pin": {
          "error": {
            "3002": {
              "source": "professional",
              "value": "This page is already pinned"
            },
            "3017": {
              "context": "error message explaining that a user lacks permission to pin a page to a community",
              "source": "professional",
              "value": "You are not authorized to pin a page to <%= community %>"
            },
            "generic": {
              "source": "professional",
              "value": "An error occured while trying to pin to this community."
            }
          },
          "success": {
            "source": "professional",
            "value": "Pinned to <%= community %>"
          }
        },
        "pinned": {
          "label": {
            "context": "This is text used in an aria-label so accessibility users can verbally hear what the button they are focused on does",
            "source": "professional",
            "value": "Click to unpin this article from <%- communityName %>"
          }
        },
        "reported": {
          "reasons": {
            "incorrect": {
              "source": "professional",
              "value": "Reported as <strong>Incorrect</strong>"
            },
            "offensive": {
              "source": "professional",
              "value": "Reported as <strong>Offensive</strong>"
            },
            "other": {
              "source": "professional",
              "value": "Reported for some other reason"
            },
            "outdated": {
              "source": "professional",
              "value": "Reported as <strong>Outdated</strong>"
            },
            "spam": {
              "source": "professional",
              "value": "Reported as <strong>Spam</strong>"
            },
            "unspecified": {
              "source": "professional",
              "value": "Reported"
            }
          }
        },
        "scheduledDate": {
          "source": "professional",
          "value": "Scheduled to publish on <strong><%= publishDate %></strong>"
        },
        "share": {
          "source": "professional",
          "value": "Add Communities"
        },
        "unpin": {
          "error": {
            "3001": {
              "source": "professional",
              "value": "This page is already unpinned"
            },
            "3017": {
              "context": "error message explaining that a user lacks permission to unpin a page from a community",
              "source": "professional",
              "value": "You are not authorized to unpin a page from <%= community %>"
            },
            "generic": {
              "source": "professional",
              "value": "An error occured while trying to unpin from this community."
            }
          },
          "success": {
            "context": "message shown when a pin, associated with an article and a community, is removed",
            "source": "professional",
            "value": "Unpinned from <%= community %>"
          }
        },
        "unpinned": {
          "label": {
            "context": "This is text used in an aria-label so accessibility users can verbally hear what the button they are focused on does",
            "source": "professional",
            "value": "Click to pin this article to <%- communityName %>"
          }
        }
      },
      "inReview": {
        "consideredForPublishing": {
          "context": "Article In Review state banner description",
          "source": "professional",
          "value": "This article will be considered for publishing"
        },
        "publish": {
          "context": "Article In Review state banner description",
          "source": "professional",
          "value": "Edit the article to make changes or publish if ready."
        },
        "title": {
          "context": "Article In Review state banner title",
          "source": "professional",
          "value": "In Review"
        }
      },
      "post": {
        "error": {
          "2001": {
            "context": "This is an error when a user is missing a required item before publishing a post",
            "source": "professional",
            "value": "Missing required parameter"
          },
          "2012": {
            "source": "professional",
            "value": "The posts feature is not enabled for this community."
          },
          "3001": {
            "source": "professional",
            "value": "Changes to this post cannot be saved."
          },
          "3059": {
            "source": "professional",
            "value": "Unfortunately this post is locked for editing by someone else. Please check back in a bit."
          }
        }
      },
      "question": {
        "actionText": {
          "delete": {
            "action": {
              "source": "professional",
              "value": "Delete Question"
            },
            "confirm": {
              "source": "professional",
              "value": "Are you sure you want to delete this Question?"
            }
          },
          "edit": {
            "source": "professional",
            "value": "Edit Question"
          },
          "locked": {
            "source": "professional",
            "value": "Locked for edit by: <%= lockedBy %>"
          },
          "print": {
            "source": "professional",
            "value": "Print Question"
          },
          "report": {
            "source": "professional",
            "value": "Report Question"
          },
          "write": {
            "source": "professional",
            "value": "Answer"
          }
        },
        "create": {
          "button": {
            "context": "verb",
            "source": "professional",
            "value": "Ask"
          },
          "placeholder": {
            "source": "professional",
            "value": "What is your question?"
          },
          "title": {
            "source": "professional",
            "value": "Ask a Question"
          }
        },
        "delete": {
          "success": {
            "source": "professional",
            "value": "\"<%= title %>\" question deleted!"
          }
        },
        "error": {
          "3001": {
            "source": "professional",
            "value": "Changes to this question cannot be saved."
          },
          "3059": {
            "source": "professional",
            "value": "Unfortunately this question is locked for editing by someone else. Please check back in a bit."
          }
        },
        "report": {
          "success": {
            "source": "professional",
            "value": "\"<%= title %>\" question reported!"
          }
        },
        "save": {
          "success": {
            "source": "professional",
            "value": "\"<%= title %>\" question saved!"
          }
        },
        "title": {
          "placeholder": {
            "source": "professional",
            "value": "Enter your question..."
          }
        }
      },
      "reference": {
        "actionText": {
          "agree": {
            "source": "professional",
            "value": "Approve"
          },
          "delete": {
            "action": {
              "source": "professional",
              "value": "Delete Link"
            },
            "confirm": {
              "source": "professional",
              "value": "Are you sure you want to delete this Link"
            }
          },
          "edit": {
            "source": "professional",
            "value": "Edit Link"
          },
          "locked": {
            "source": "professional",
            "value": "Locked for edit by: <%= lockedBy %>"
          },
          "print": {
            "source": "professional",
            "value": "Print Link"
          },
          "report": {
            "source": "professional",
            "value": "Report Link"
          }
        },
        "delete": {
          "success": {
            "source": "professional",
            "value": "\"<%- title %>\" link deleted!"
          }
        },
        "error": {
          "3001": {
            "source": "professional",
            "value": "Changes to this link cannot be saved."
          },
          "3059": {
            "source": "professional",
            "value": "Unfortunately this link is locked for editing by someone else. Please check back in a bit."
          }
        },
        "report": {
          "success": {
            "source": "professional",
            "value": "\"<%- title %>\" link reported!"
          }
        },
        "save": {
          "success": {
            "source": "professional",
            "value": "\"<%- title %>\" link saved!"
          }
        },
        "title": {
          "placeholder": {
            "source": "professional",
            "value": "Link Title"
          }
        }
      },
      "training": {
        "actionText": {
          "delete": {
            "action": {
              "source": "professional",
              "value": "Delete Article"
            },
            "confirm": {
              "source": "professional",
              "value": "Are you sure you want to delete this article published from <%= t('appNames.admin') %> Content?"
            }
          },
          "edit": {
            "source": "professional",
            "value": "Edit Article"
          },
          "print": {
            "source": "professional",
            "value": "Print Article"
          },
          "report": {
            "source": "professional",
            "value": "Report Article"
          }
        },
        "delete": {
          "success": {
            "source": "professional",
            "value": "\"<%- title %>\" Training Module Article deleted!"
          }
        },
        "editWarning": {
          "source": "professional",
          "value": "The article was created from <%= t('appNames.admin') %> Content. Changes to the title or body are not allowed from <%= t('appNames.knowledge') %>."
        },
        "error": {
          "3001": {
            "source": "professional",
            "value": "Changes to this Training Article cannot be saved."
          },
          "3059": {
            "source": "professional",
            "value": "Unfortunately this Training Module Article is locked for editing by someone else. Please check back in a bit."
          }
        },
        "report": {
          "success": {
            "source": "professional",
            "value": "\"<%- title %>\" Training Module Article reported!"
          }
        },
        "save": {
          "success": {
            "source": "professional",
            "value": "\"<%- title %>\" Training Module Article saved!"
          }
        }
      }
    },
    "pageView": {
      "editBlocked": {
        "source": "professional",
        "value": "You don't have sufficient permissions in View Access Communities to edit this article."
      },
      "expired": {
        "source": "professional",
        "value": "This article is expired."
      },
      "history": {
        "updatedBy": {
          "source": "professional",
          "value": "Updated by"
        },
        "version": {
          "source": "professional",
          "value": "Version <%- revision %>"
        },
        "versionHistory": {
          "source": "professional",
          "value": "Version History"
        }
      },
      "moreMenu": {
        "ariaLabel": {
          "hide": {
            "source": "professional",
            "value": "Click to hide more details"
          },
          "show": {
            "source": "professional",
            "value": "Click to show more details"
          }
        }
      },
      "report": {
        "by": {
          "source": "professional",
          "value": "Reported by <%= name %> on <%- date %>"
        },
        "reasons": {
          "incorrect": {
            "source": "professional",
            "value": "This has been reported as incorrect."
          },
          "offensive": {
            "source": "professional",
            "value": "This has been reported as offensive."
          },
          "other": {
            "source": "professional",
            "value": "This has been reported."
          },
          "outdated": {
            "source": "professional",
            "value": "This has been reported as outdated."
          },
          "spam": {
            "source": "professional",
            "value": "This has been reported as spam."
          }
        }
      }
    },
    "print": {
      "contributors": {
        "source": "professional",
        "value": "Contributors:"
      }
    },
    "report": {
      "comment": {
        "label": {
          "source": "professional",
          "value": "Comment"
        },
        "placeholder": {
          "source": "professional",
          "value": "Write a comment here"
        }
      },
      "reportBtn": {
        "source": "professional",
        "value": "Report"
      },
      "title": {
        "source": "professional",
        "value": "Report this article"
      },
      "type": {
        "incorrect": {
          "source": "professional",
          "value": "Incorrect"
        },
        "offensive": {
          "source": "professional",
          "value": "Offensive"
        },
        "other": {
          "source": "professional",
          "value": "Other"
        },
        "outdated": {
          "source": "professional",
          "value": "Outdated"
        },
        "spam": {
          "source": "professional",
          "value": "Spam"
        }
      }
    },
    "share": {
      "error": {
        "source": "professional",
        "value": "Error sharing to some communities."
      },
      "meta": {
        "source": "professional",
        "value": "These communities will have read-only access."
      },
      "title": {
        "source": "professional",
        "value": "View Access Communities"
      }
    },
    "tagBar": {
      "removeTagFilter": {
        "context": "Text read out by a screen reader explaining that clicking this button will remove a filter, called a tag",
        "source": "professional",
        "value": "Remove tag filter"
      }
    },
    "tagBarPrompt": {
      "source": "professional",
      "value": "Filter by tag"
    }
  },
  "discoverArticlePicker": {
    "attachButton": {
      "source": "professional",
      "value": "Attach"
    },
    "card": {
      "community": {
        "context": "string that identifies the community where the article was published",
        "source": "professional",
        "value": "Published in <%= community %>"
      }
    },
    "discoverLinkLabel": {
      "source": "professional",
      "value": "Discover Link"
    },
    "input": {
      "placeholder": {
        "source": "professional",
        "value": "Paste a Discover URL"
      }
    }
  },
  "downloadsExports": {
    "downloads": {
      "types": {
        "AnswerHistory": {
          "source": "professional",
          "value": "Exam Details"
        },
        "AnswersByTopicLevels": {
          "source": "professional",
          "value": "Exam Summary"
        },
        "AnswersByUser": {
          "source": "professional",
          "value": "Exam Summary"
        },
        "BehaviourTranslationExport": {
          "source": "professional",
          "value": "Behavior Translations"
        },
        "CertificationDetails": {
          "source": "professional",
          "value": "Certification Details"
        },
        "ContentExpiry": {
          "context": "A report type that describes the expiration dates of certain content types.",
          "source": "professional",
          "value": "Content Expiry Report"
        },
        "ContentUsage": {
          "source": "professional",
          "value": "Content Usage Report"
        },
        "DueSubjects": {
          "source": "professional",
          "value": "Due Subjects"
        },
        "DueTopics": {
          "source": "professional",
          "value": "Due Topics"
        },
        "EventEnrollment": {
          "source": "professional",
          "value": "Event Enrollment"
        },
        "EventSessionRoster": {
          "source": "professional",
          "value": "Session Roster"
        },
        "ExamDetails": {
          "source": "professional",
          "value": "Exam Details"
        },
        "ExamSummary": {
          "source": "professional",
          "value": "Exam Summary"
        },
        "FailedInspectionItems": {
          "source": "professional",
          "value": "Failed Inspection Items"
        },
        "FailedInspectionLocations": {
          "source": "professional",
          "value": "Failed Inspection Counts by Team"
        },
        "ImpactTopicScores": {
          "source": "professional",
          "value": "Impact KPI Linked Topic Knowledge Breakdown"
        },
        "InspectionFormDetails": {
          "source": "professional",
          "value": "Inspection Forms Details"
        },
        "InspectionForms": {
          "source": "professional",
          "value": "Inspection Forms Summary"
        },
        "KnowledgeSummary": {
          "source": "professional",
          "value": "Knowledge Summary"
        },
        "LearnerHistory": {
          "source": "professional",
          "value": "Learner History"
        },
        "LocationList": {
          "source": "professional",
          "value": "Team List"
        },
        "MilestoneProgressDetails": {
          "source": "professional",
          "value": "Milestone Progress Details"
        },
        "MilestoneProgressSummary": {
          "source": "professional",
          "value": "Milestone Progress Summary"
        },
        "ObservationDetails": {
          "context": "This is the label for the 'Behavior Details' data type export (which now includes both Observations and Evaluations) as it will appear in the Admin>Exports tables",
          "source": "professional",
          "value": "Observation/Evaluation Details"
        },
        "Observations": {
          "context": "This is the label for the 'Behavior Observations' data type export (which now includes both Observations and Evaluations) as it will appear in the Admin>Exports tables",
          "source": "professional",
          "value": "Observations/Evaluations"
        },
        "Observers": {
          "context": "This is the label for the 'Behavior Observers' data type export (which now includes both Observers and Evaluators) as it will appear in the Admin>Exports tables",
          "source": "professional",
          "value": "Observers/Evaluators"
        },
        "OverConfident": {
          "source": "professional",
          "value": "Over Confident"
        },
        "OverdueTopics": {
          "source": "professional",
          "value": "Overdue Topics"
        },
        "Participation": {
          "source": "professional",
          "value": "Participation"
        },
        "PoorPerformers": {
          "source": "professional",
          "value": "Poor Performers"
        },
        "PrizeWinners": {
          "source": "professional",
          "value": "Winners"
        },
        "QuestionExport": {
          "source": "professional",
          "value": "Questions"
        },
        "QuestionFeedback": {
          "source": "professional",
          "value": "Question Feedback Report"
        },
        "QuestionSummary": {
          "source": "professional",
          "value": "Question Summary"
        },
        "QuestionTranslationExport": {
          "source": "professional",
          "value": "Question Translations"
        },
        "ReviewItemTranslationExport": {
          "source": "professional",
          "value": "Inspection Translations"
        },
        "SurveyComments": {
          "source": "professional",
          "value": "Survey Comments"
        },
        "SurveyDetails": {
          "source": "professional",
          "value": "Survey Details"
        },
        "SurveySummary": {
          "source": "professional",
          "value": "Survey Summary"
        },
        "SurveyTranslationExport": {
          "source": "professional",
          "value": "Survey Translations"
        },
        "TaskTranslationExport": {
          "source": "professional",
          "value": "Task Translations"
        },
        "TopPerformers": {
          "source": "professional",
          "value": "Top Performers"
        },
        "TopicProgress": {
          "source": "professional",
          "value": "Topic Progress"
        },
        "TranslationExport": {
          "source": "professional",
          "value": "Translations"
        },
        "UnderConfident": {
          "source": "professional",
          "value": "Under Confident"
        },
        "UserActivity": {
          "source": "professional",
          "value": "User Activity"
        },
        "UserAgreementAcceptRecords": {
          "source": "professional",
          "value": "User Agreement Acceptance Records"
        },
        "UserDetails": {
          "source": "professional",
          "value": "User Details"
        },
        "UserGraduations": {
          "source": "professional",
          "value": "User Graduations"
        },
        "UserList": {
          "source": "professional",
          "value": "User List"
        },
        "UserParticipation": {
          "source": "professional",
          "value": "User Participation"
        },
        "UserSummary": {
          "source": "professional",
          "value": "User Summary"
        }
      }
    }
  },
  "errorPage": {
    "details": {
      "source": "professional",
      "value": "Error Details"
    },
    "oops": {
      "source": "professional",
      "value": "Oops!"
    }
  },
  "errors": {
    "400": {
      "source": "professional",
      "value": "400 Error. Error code <%- errCode %>. Logging out."
    },
    "3063": {
      "source": "professional",
      "value": "Moving this team would violate the business unit boundaries. It's likely this team is the root node of a business unit and cannot be moved or has other relationship boundaries."
    },
    "3086": {
      "source": "professional",
      "value": "A business unit can be assigned at most one location and you may not nest business units."
    },
    "9021": {
      "source": "professional",
      "value": "Your IP address is not authorized for this app.  Please click the button or refresh the page when you are on an approved network.  If you continue to have problems connecting, please contact your administrator."
    },
    "Community": {
      "3001": {
        "context": "This error message lets the administrator know that the item they are trying to delete has already been deleted a short while ago.",
        "source": "professional",
        "value": "The community you tried to delete has been deleted by someone else, or does not exist anymore."
      },
      "3007": {
        "source": "professional",
        "value": "You can't delete a community with pages in it. Delete all the pages before trying to delete this community again."
      }
    },
    "PageReport": {
      "required": {
        "comment": {
          "source": "professional",
          "value": "You must provide a valid comment."
        },
        "reportType": {
          "source": "professional",
          "value": "You must provide a valid reporting reason."
        }
      }
    },
    "Question": {
      "required": {
        "community": {
          "source": "professional",
          "value": "You must select a community."
        },
        "language": {
          "source": "professional",
          "value": "You must select a language."
        },
        "title": {
          "source": "professional",
          "value": "You must provide a valid question."
        }
      }
    },
    "Tag": {
      "3001": {
        "context": "This error message lets the administrator know that the item they are trying to delete has already been deleted a short while ago.",
        "source": "professional",
        "value": "The tag you tried to delete has been deleted by someone else, or does not exist anymore."
      }
    },
    "VideoPackageModel": {
      "requiresValidUrl": {
        "source": "professional",
        "value": "One of the provided URLs was not a well formed video URL of a supported format. Please check your work."
      }
    },
    "VideoPlayer": {
      "mediaErrorRecovery": {
        "source": "professional",
        "value": "A video error occurred during playback. Click play to resume."
      },
      "newWindow": {
        "source": "professional",
        "value": "There was an error playing the video in a new window."
      }
    },
    "auth": {
      "7": {
        "source": "professional",
        "value": "Please remove this bookmark or contact your administrator."
      },
      "401": {
        "source": "professional",
        "value": "Authentication failed, please contact your administrator."
      },
      "3030": {
        "source": "professional",
        "value": "The user is currently suspended. Please contact your administrator for assistance."
      },
      "3141": {
        "source": "professional",
        "value": "You are not on the clock. Please click the button or refresh the page when you are on the clock."
      },
      "9005": {
        "source": "professional",
        "value": "Your username was not found in the system.  Please contact your administrator for help."
      },
      "9006": {
        "source": "professional",
        "value": "The system received an unknown response.  Please report this error to your administrator."
      },
      "9021": {
        "source": "professional",
        "value": "Your IP address is not authorized for this app.  Please click the button or refresh the page when you are on an approved network.  If you continue to have problems connecting, please contact your administrator."
      },
      "9028": {
        "source": "professional",
        "value": "A Single Sign On processing error occurred, please contact your administrator."
      },
      "11000": {
        "source": "professional",
        "value": "Access to Axonify for this user is restricted to the corporate network. Please connect and try logging in again."
      },
      "genericError": {
        "source": "professional",
        "value": "The system encountered a communication error. Please report this error to your administrator."
      },
      "oauthError": {
        "source": "professional",
        "value": "The system encountered an error logging in with the OAuth provider. Please report this error to your administrator."
      }
    },
    "genericError": {
      "source": "professional",
      "value": "Something happened while you were using the app."
    },
    "nativeWrapper": {
      "1000": {
        "source": "professional",
        "value": "There was an error opening the link.  Please contact your administrator."
      },
      "1001": {
        "source": "professional",
        "value": "There was an error opening the link.  Make sure the link starts with 'http://' or 'https://'."
      },
      "1000-microsoft-edge": {
        "source": "professional",
        "value": "There was an error opening Microsoft Edge.  Please contact your administrator."
      }
    },
    "noConnection": {
      "source": "professional",
      "value": "There is no internet connection.  Please contact your network administrator or click Continue when you are reconnected."
    },
    "serverError": {
      "source": "professional",
      "value": "Something happened on the server while you were using the app. Please click Retry or Refresh the page."
    },
    "timeout": {
      "source": "professional",
      "value": "The network request timed out.  Please contact your network administrator or click Continue when you are reconnected."
    },
    "validators": {
      "string": {
        "general": {
          "source": "professional",
          "value": "Invalid character entered."
        }
      }
    }
  },
  "events": {
    "scheduledEvents": {
      "addUsers": {
        "id": {
          "source": "professional",
          "value": "ID: <%= id %>"
        },
        "location": {
          "source": "professional",
          "value": "Team: <%= name %>"
        }
      },
      "hiddenUsers": {
        "count": {
          "context": "This is the text showing the number of additional, unnamed attendees enrolled in an event (whom the user is not authorized to see)",
          "source": "professional",
          "value": "<%= count %> attending"
        },
        "label": {
          "context": "This is the label for the event enrollment list element that shows number of additional attendees who do not have details",
          "source": "professional",
          "value": "Other Groups"
        }
      }
    }
  },
  "externalLink": {
    "launchExternalLink": {
      "source": "professional",
      "value": "Launch external link"
    }
  },
  "feedback": {
    "title": {
      "context": "This is the generic title for the Feedback section",
      "source": "professional",
      "value": "Feedback"
    }
  },
  "fileFactory": {
    "fileTypeNotSupported": {
      "source": "professional",
      "value": "File type not supported."
    },
    "noDocumentsAllowed": {
      "source": "professional",
      "value": "Document files are not supported."
    },
    "noVideosAllowed": {
      "source": "professional",
      "value": "Video files are not supported."
    }
  },
  "fileUploader": {
    "fileEmpty": {
      "source": "professional",
      "value": "The file is empty or a folder. Please try another file."
    },
    "fileTooBig": {
      "source": "professional",
      "value": "The file is too large. The maximum allowed size is <%- maximumSize %>MB."
    },
    "fileTypeNotAllowed": {
      "source": "professional",
      "value": "The type of file(s) you selected is not allowed. Please try another file."
    }
  },
  "flash": {
    "browserNotSupported": {
      "source": "professional",
      "value": "You are using an unsupported browser, please update to a more modern browser for the best user experience"
    },
    "common": {
      "3008": {
        "source": "professional",
        "value": "The item cannot be found."
      },
      "3064": {
        "source": "professional",
        "value": "The text entered contains HTML tags, which are not allowed.  Fix the entered text to not include HTML tags."
      },
      "3109": {
        "source": "professional",
        "value": "The selected filter can not be used for this data source."
      }
    },
    "could_not_open_popup": {
      "source": "professional",
      "value": "The app could not open a pop up with the screenshot, most likely because pop ups have been blocked by the browser. Please disable pop up blocking to retrieve your screenshot."
    },
    "download": {
      "downloadExportsDescriptionNoLink": {
        "source": "professional",
        "value": "Go to the Exports section to download the file"
      },
      "downloadExportsLink": {
        "source": "professional",
        "value": "Go to the <a href=\"#downloads\">Exports section</a> to download the file"
      },
      "error": {
        "source": "professional",
        "value": "An error occured during report export"
      },
      "queue": {
        "source": "professional",
        "value": "<%= fileType %> has been queued for download."
      },
      "tooManyRequests": {
        "source": "professional",
        "value": "Current export queue is still processing. Please try again later."
      }
    },
    "downloadNotSupported": {
      "source": "professional",
      "value": "Download is not supported in-app, please try again in a browser."
    },
    "externalLinkNotSupported": {
      "source": "professional",
      "value": "External Links are disabled."
    },
    "externalWindowPopUpBlocked": {
      "source": "professional",
      "value": "The window could not be opened, most likely because pop-ups have been blocked by the browser. Please disable pop-up blocking and try again."
    },
    "failed_to_upload_file": {
      "source": "professional",
      "value": "Failed to upload the file."
    },
    "form_info_incorrect": {
      "source": "professional",
      "value": "Some information in the form is missing or incorrect."
    },
    "infoMissing": {
      "source": "professional",
      "value": "Some information in the form is missing or incorrect."
    },
    "internetExplorerGoingAway": {
      "source": "professional",
      "value": "Your version of Internet Explorer is no longer supported. Axonify will not continue to work properly in newer versions of the platform."
    },
    "invalidLogin": {
      "source": "professional",
      "value": "Invalid username or password."
    },
    "lockedOut": {
      "source": "professional",
      "value": "You have been locked out. Please try again in 15 minutes."
    },
    "mediaDownload": {
      "error": {
        "context": "Flash message that appears when a download of a media file fails.",
        "source": "professional",
        "value": "An error occured while trying to download the media file."
      }
    },
    "mfaInvalid": {
      "source": "professional",
      "value": "Invalid or expired verification code"
    },
    "noInternet": {
      "source": "professional",
      "value": "No Internet connection."
    },
    "printPopUpBlocked": {
      "source": "professional",
      "value": "The print window could not be opened, most likely because pop-ups have been blocked by the browser. Please disable pop-up blocking and try again."
    },
    "serviceDown": {
      "source": "professional",
      "value": "Service is down. Try again shortly."
    },
    "sessionCompleted": {
      "source": "professional",
      "value": "You have completed your activities. Check back tomorrow!"
    },
    "sessionExpired": {
      "source": "professional",
      "value": "Your session has expired. Please log in again."
    },
    "simpleFileUploadRetry": {
      "source": "professional",
      "value": "Something went wrong while uploading. Please try again."
    },
    "super_popup_blocked": {
      "source": "professional",
      "value": "The application could not open a window to the reset page since popups were blocked. Please enable popups for Axonify and try again."
    },
    "updateRequired": {
      "source": "professional",
      "value": "A new version of Axonify is available. To update, save your work and click here to reload the page."
    },
    "userSuspended": {
      "source": "professional",
      "value": "The user is currently suspended. Please contact your administrator for assistance."
    }
  },
  "formLabels": {
    "DateConditionModel": {
      "dateConditionType": {
        "source": "professional",
        "value": "Date Condition"
      },
      "dateType": {
        "source": "professional",
        "value": "Date Type"
      },
      "numOfDaysBeyond": {
        "source": "professional",
        "value": "Days before user is automatically added"
      },
      "numOfDaysWithin": {
        "source": "professional",
        "value": "Number of days before user is removed"
      },
      "specificDate": {
        "source": "professional",
        "value": "Specific Date"
      }
    }
  },
  "froala": {
    "edit": {
      "table": {
        "context": {
          "fullWidth": {
            "context": "Name of a table setting that renders a table at its maximal width",
            "source": "professional",
            "value": "Width Expanded"
          }
        }
      }
    },
    "wordPaste": {
      "warning": {
        "source": "professional",
        "value": "Your browser does not fully support WordPaste, please review your document to ensure its accuracy"
      }
    }
  },
  "games": {
    "AtomicAtoms": {
      "context": "This string is the name of a game. This is a puzzle game where you connect atoms to form a molecule by selecting an atom, choosing a direction for it to move in and navigating through increasingly difficult levels. The game also features questions that appear when an atom moves over a question mark.",
      "source": "professional",
      "value": "Extreme Atoms"
    },
    "BallBob": {
      "context": "This string is the name of a game. This is a puzzle game where you remove blocks to guide a character named Bob to his bowl in each level. Bob can use various objects like swings, levers, and other balls to progress, and reaching the bowl will present a bonus question for extra time.",
      "source": "professional",
      "value": "Ball Bob"
    },
    "BattleFish": {
      "context": "This string is the name of a game. The game involves controlling a battle fish and making it grow in size while attacking and eliminating enemy fish. Hitting a fish marked with a question mark  will trigger a question.",
      "source": "professional",
      "value": "Battle Fish"
    },
    "BlackAway": {
      "context": "This string is the name of a game. The game requires the player to move a block using swiping or arrow keys and position it vertically on an alligator to win. Falling off the edges of the screen will result in a loss.",
      "source": "professional",
      "value": "Sponge Toss"
    },
    "Block": {
      "context": "This string is the name of a game. The game is a block-clearing puzzle where the objective is to select groups of three or more blocks of the same color using the keyboard or mouse. The more blocks you clear, the higher your score.",
      "source": "professional",
      "value": "Block Rush"
    },
    "BlockArt": {
      "context": "This string is the name of a game. The game is a puzzle where the player must select a colored block and use it to reproduce a pattern in the center of the screen before it disappears after the third block is placed.",
      "source": "professional",
      "value": "Block Art"
    },
    "Breaker": {
      "context": "This string is the name of a game. The game is a bouncing and ricocheting game where the objective is to navigate through an endless supply of colorful levels by launching a ball and using it to gain speed, power, and points multiplier.",
      "source": "professional",
      "value": "Breaker Ball"
    },
    "Bubble": {
      "context": "This string is the name of a game. The game is a bubble shooter where the player aims and fires colored bubbles from the bottom of the screen to match and clear bubbles of the same color. Points are earned for matching multiple bubbles.",
      "source": "professional",
      "value": "Bubble Shooter"
    },
    "BumpOut": {
      "context": "This string is the name of a game. In this game, the player clicks or taps on a bubble and drags or swipes it in the direction of another bubble to knock it off the board. The goal is to clear the board until only one bubble remains, and the player can build up a score multiplier by completing multiple boards in a row.",
      "source": "professional",
      "value": "Bump Out"
    },
    "Catcher": {
      "context": "This string is the name of a game. The game is a shape catching game where the player controls a shape at the bottom of the screen to catch matching shapes and earn points. The game can be controlled with the keyboard or mouse, and catching the same colored candy results in even more points",
      "source": "professional",
      "value": "Catcher"
    },
    "Cheese": {
      "context": "This string is the name of a game. The game requires the player to guide a mouse and collect all the cheese by swiping the screen or using arrow keys to move the mouse in the desired direction.",
      "source": "professional",
      "value": "Cheese Quest"
    },
    "Collisions": {
      "context": "This string is the name of a game. The game involves creating bubbles by clicking or touching anywhere on the screen, and then triggering a collision with the moving bubbles to earn points. Power up bubbles can also be collided with for extra effects, and only one collision can be active at a time.",
      "source": "professional",
      "value": "Collisions"
    },
    "Convergence": {
      "context": "This string is the name of a game. This game requires the player to click on empty cells to merge adjacent filled cells until only one cell is left to win. If the last filled cell is on a star space, bonus points are awarded.",
      "source": "professional",
      "value": "Convergence"
    },
    "CurlingUpdate": {
      "context": "This string is the name of a game. The game is a turn-based game where the player and the computer alternate shots. The player taps to choose the angle, power, and curve of their shot, aiming to land the rock as close as possible to the center of the target to earn points.",
      "source": "professional",
      "value": "Curling"
    },
    "Curves": {
      "context": "This string is the name of a game. The game is a puzzle where the objective is to connect circles on the left to circles on the right by rotating tiles between the circles, with clicking on the tile to rotate it clockwise and align the curves. The longer the chain, the more points the player earns.",
      "source": "professional",
      "value": "Learning Curves"
    },
    "Elements": {
      "context": "This string is the name of a game. The objective of the game is to move tiles to form matching chains of symbols vertically or horizontally. Players can create special tiles with additional effects by matching four or more tiles, while matching two chains together creates a wildcard.",
      "source": "professional",
      "value": "Elements"
    },
    "FieldGoal": {
      "context": "This string is the name of a game. The game involves kicking a football between goal posts within a set time limit while taking into account the wind direction. The player clicks below the ball and moves the mouse in the desired direction, and a question appears when the player misses, goes wide, or runs out of time.",
      "source": "professional",
      "value": "Field Goal"
    },
    "FoodSearch": {
      "context": "This string is the name of a game. This game involves searching a board for patterns listed and clicking and dragging to highlight them vertically, horizontally, and diagonally to earn points and eventually a bonus question.",
      "source": "professional",
      "value": "Food Search"
    },
    "GemJam": {
      "context": "This string is the name of a game. This game involves arranging groups of 4 gems to match a pattern shown in the top left corner of the screen in any orientation. The goal is to earn as many points as possible by matching multiple patterns.",
      "source": "professional",
      "value": "Gem Jam"
    },
    "Golf": {
      "context": "This string is the name of a game. This game involves setting the direction and power of a ball by clicking or tapping, while also considering the wind, to earn points by getting the ball closer to the hole.",
      "source": "professional",
      "value": "Golf Game"
    },
    "Head2Head": {
      "context": "This string is the name of a game. In this game, the player selects an opponent and must answer questions correctly and faster than their opponent in order to earn more points and win.",
      "source": "professional",
      "value": "Head 2 Head"
    },
    "Invasion": {
      "context": "This string is the name of a game. In this game, players aim to bring as many cows as possible back to the mothership while avoiding enemy fire. They can create a path by clicking on tiles, anticipating enemy attacks, and choosing the safest route.",
      "source": "professional",
      "value": "Invasion"
    },
    "Jigsaw": {
      "context": "This string is the name of a game. Players must choose a puzzle square on the board to compete for the highest score, with blue squares owned by their team and red squares owned by the opposing team. The team with the most squares claimed at the end of the game wins.",
      "source": "professional",
      "value": "Jigsaw"
    },
    "LightPuzzle": {
      "context": "This string is the name of a game. The game is a territory claiming game where the player collects points in any area they choose on the map. Bonus points are awarded for reaching the exit with as many coins as possible.",
      "source": "professional",
      "value": "Cave Crawl"
    },
    "LightsOut": {
      "context": "This string is the name of a game. In the game, the player drags and pulls balls to launch them and break a light bulb within a set time frame while overcoming obstacles.",
      "source": "professional",
      "value": "Lights Out"
    },
    "Memory": {
      "context": "This string is the name of a game. This is a memory matching game where the goal is to find matching pairs by flipping two cards over. Matching pairs earn points, and the game continues until all pairs have been found.",
      "source": "professional",
      "value": "Memory Cards"
    },
    "Monkey": {
      "context": "This string is the name of a game. In this game, the player must launch monkeys to collect bananas and destroy obstacles by dragging and releasing them from a slingshot, adjusting the angle and distance to make the shot.",
      "source": "professional",
      "value": "Monkey Bizness"
    },
    "Pinball": {
      "context": "This string is the name of a game. In this game, the player controls the paddles on a table using mouse clicks, taps, or arrow keys to keep the ball in play. The objective is to prevent the ball from falling off the bottom of the screen by using the paddles to hit it back up towards obstacles and points.",
      "source": "professional",
      "value": "Pinball"
    },
    "Pixel": {
      "context": "This string is the name of a game. In this game, the goal is to flood the board with the same color within 25 moves. The player must select a paint can from the bottom of the screen that matches one of the colors touching the flooded area to expand the flood area.",
      "source": "professional",
      "value": "Color Fill"
    },
    "Quiz": {
      "context": "This string is the name of a game. This game displays a word puzzle after answering questions, and players have a limited time to solve it.",
      "source": "professional",
      "value": "Quiz Show"
    },
    "Shapes": {
      "context": "This string is the name of a game. The game requires the player to swap positions with shapes to make a match of three or more identical shapes in a row using a white square.",
      "source": "professional",
      "value": "Shuffle"
    },
    "Simon": {
      "context": "This string is the name of a game. The game requires the player to click on blocks in the order they appear on the screen. The score increases as the player maintains a longer correct sequence.",
      "source": "professional",
      "value": "Simon Says"
    },
    "Simon2": {
      "context": "This string is the name of a game. This is a Memory game where the player is shown a squence of actions and is required to  remember and re-enact that sequence.",
      "source": "professional",
      "value": "Sequences"
    },
    "Snake": {
      "context": "This string is the name of a game. The game is a classic snake game where the player controls a snake to collect fruits and questions while avoiding the boundaries. The longer the snake grows, the higher the score.",
      "source": "professional",
      "value": "Snake"
    },
    "Soccer": {
      "context": "This string is the name of a game. In this soccer game, the player clicks and drags to choose the direction and power of their shot, then clicks on the ball to kick it. The player must pass the ball to their teammates while avoiding opponents to make it up the field and score a goal.",
      "source": "professional",
      "value": "Kick-Off"
    },
    "Space": {
      "context": "This string is the name of a game. The game is a space shooter where the player must destroy all spaceships within a time limit.",
      "source": "professional",
      "value": "Space Attacks"
    },
    "SpaceRocks": {
      "context": "This string is the name of a game. The game is a rhythm-based rock-catching game where the player matches the pattern of falling rocks by tapping them as they reach the bottom of the screen. The more rocks the player catches, the higher the score.",
      "source": "professional",
      "value": "Space Rocks"
    },
    "Sum": {
      "context": "This string is the name of a game. The game is a number puzzle game where the player must click connected numbers to create a sum equal to the target number shown. The longer the sequence of numbers, the higher the score.",
      "source": "professional",
      "value": "Sum Up"
    },
    "Summer": {
      "context": "This string is the name of a game. This is a collection of minigames themed around summer and track and field",
      "source": "professional",
      "value": "Summer Games"
    },
    "Sushi": {
      "context": "This string is the name of a game. The game is a sushi-slashing game where the player swipes across the screen to slash as much sushi as possible before time runs out. If the player misses, it reflects negatively on their respect and points.",
      "source": "professional",
      "value": "Sushi"
    },
    "TeamRunner": {
      "context": "This string is the name of a game. The game is a territory claiming game where blue areas are owned by the player's team and red areas are owned by the opposing team. The player can choose any area on the map and claim it by running the longest distance.",
      "source": "professional",
      "value": "Sky Run"
    },
    "TurtleHurtle": {
      "context": "This string is the name of a game. In this game, the player controls a turtle who has fallen behind their family and must catch up using hills on the ocean floor. They collect coins and boosts to earn points while strategically tapping and holding to descend or increase speed on hills.",
      "source": "professional",
      "value": "Turtle Hurtle"
    },
    "Unicycle": {
      "context": "This string is the name of a game. In this game, the player must lean and steer a unicycle using arrow keys or touch controls, collecting coins and avoiding obstacles to stay upright as long as possible. The difficulty increases as time passes, providing a greater challenge.",
      "source": "professional",
      "value": "Pirate Peril"
    },
    "Zombie": {
      "context": "This string is the name of a game. This is a game where the player taps incoming zombies to destroy them. As the levels progress, zombies appear faster, and destroying zombies holding questions triggers a question to appear.",
      "source": "professional",
      "value": "Zombie Taps"
    }
  },
  "general": {
    "add": {
      "source": "professional",
      "value": "Add"
    },
    "addNew": {
      "source": "professional",
      "value": "Add New"
    },
    "after": {
      "context": "After something else",
      "source": "professional",
      "value": "After"
    },
    "all": {
      "source": "professional",
      "value": "All"
    },
    "allTeams": {
      "source": "professional",
      "value": "All Teams"
    },
    "apply": {
      "source": "professional",
      "value": "Apply"
    },
    "archive": {
      "source": "professional",
      "value": "Archive"
    },
    "attach": {
      "source": "professional",
      "value": "Attach"
    },
    "back": {
      "source": "professional",
      "value": "Back"
    },
    "boost": {
      "source": "professional",
      "value": "Boost"
    },
    "cancel": {
      "source": "professional",
      "value": "Cancel"
    },
    "checkBackSoon": {
      "context": "Revisit at a later time",
      "source": "professional",
      "value": "Check back soon!"
    },
    "clearAll": {
      "source": "professional",
      "value": "Clear All"
    },
    "clearSearch": {
      "source": "professional",
      "value": "Clear search"
    },
    "clone": {
      "source": "professional",
      "value": "Copy"
    },
    "close": {
      "source": "professional",
      "value": "Close"
    },
    "comment": {
      "source": "professional",
      "value": "Comment"
    },
    "confidence": {
      "source": "professional",
      "value": "Confidence"
    },
    "confirmDelete": {
      "source": "professional",
      "value": "Are you sure you want to delete this?"
    },
    "continue": {
      "source": "professional",
      "value": "Continue"
    },
    "create": {
      "source": "professional",
      "value": "Create"
    },
    "currentCompletion": {
      "context": "A generic string to explain to the user that the progress bar they are focused on is completed by a specific amount. The amount text comes from the element this string is attached to.",
      "source": "professional",
      "value": "Currently Completed"
    },
    "delete": {
      "source": "professional",
      "value": "Delete"
    },
    "description": {
      "label": {
        "source": "professional",
        "value": "Description:"
      },
      "name": {
        "source": "professional",
        "value": "Description"
      }
    },
    "details": {
      "source": "professional",
      "value": "Details"
    },
    "discard": {
      "source": "professional",
      "value": "Discard"
    },
    "dismiss": {
      "context": "Dismiss label for dismiss buttons and other components",
      "source": "professional",
      "value": "Dismiss"
    },
    "done": {
      "source": "professional",
      "value": "Done"
    },
    "download": {
      "source": "professional",
      "value": "Download"
    },
    "downloadUnavailableInMobileApp": {
      "source": "professional",
      "value": "Download unavailable in the mobile app."
    },
    "edit": {
      "source": "professional",
      "value": "Edit"
    },
    "empty": {
      "source": "professional",
      "value": "There is no data available."
    },
    "emptyRightPane": {
      "source": "professional",
      "value": "Select an item to view"
    },
    "emptySearch": {
      "source": "professional",
      "value": "No results for this search."
    },
    "ends": {
      "source": "professional",
      "value": "Ends"
    },
    "error": {
      "source": "professional",
      "value": "Error!"
    },
    "ex": {
      "source": "professional",
      "value": "Ex:"
    },
    "export": {
      "source": "professional",
      "value": "Export"
    },
    "exportDetails": {
      "source": "professional",
      "value": "Export Details"
    },
    "externalCode": {
      "label": {
        "source": "professional",
        "value": "External Code:"
      },
      "name": {
        "source": "professional",
        "value": "External Code"
      }
    },
    "false": {
      "context": "boolean false",
      "source": "professional",
      "value": "False"
    },
    "feedbackLabel": {
      "desktop": {
        "source": "professional",
        "value": "Send Feedback"
      }
    },
    "filter": {
      "allEmployees": {
        "source": "professional",
        "value": "All Employees"
      },
      "default": {
        "source": "professional",
        "value": "My Team"
      }
    },
    "first": {
      "context": "Click this button to go to the first page of results",
      "source": "professional",
      "value": "First"
    },
    "headers": {
      "date": {
        "source": "professional",
        "value": "Date"
      }
    },
    "howItWorks": {
      "source": "professional",
      "value": "How it Works"
    },
    "import": {
      "source": "professional",
      "value": "Import"
    },
    "info": {
      "source": "professional",
      "value": "Info!"
    },
    "jobTitleLabel": {
      "source": "professional",
      "value": "Job Title:"
    },
    "language": {
      "source": "professional",
      "value": "Language"
    },
    "languages": {
      "source": "professional",
      "value": "Languages"
    },
    "last": {
      "context": "Click this button to go to the last page of results",
      "source": "professional",
      "value": "Last"
    },
    "launch": {
      "source": "professional",
      "value": "Launch"
    },
    "loadingData": {
      "source": "professional",
      "value": "Loading data..."
    },
    "locationLabel": {
      "source": "professional",
      "value": "Team:"
    },
    "locked": {
      "source": "professional",
      "value": "Locked"
    },
    "new": {
      "source": "professional",
      "value": "New"
    },
    "next": {
      "source": "professional",
      "value": "Next"
    },
    "no": {
      "source": "professional",
      "value": "No"
    },
    "noValue": {
      "source": "professional",
      "value": "--"
    },
    "notApplicable": {
      "source": "professional",
      "value": "N/A"
    },
    "numberSelected": {
      "context": "A generic placeholder message to show a count of how many options have been selected",
      "source": "professional",
      "value": "<%- count %> Selected"
    },
    "occurrences": {
      "context": "How many times something has happened",
      "source": "professional",
      "value": "occurrences"
    },
    "off": {
      "context": "On/Off",
      "source": "professional",
      "value": "Off"
    },
    "ok": {
      "source": "professional",
      "value": "OK"
    },
    "on": {
      "context": "On/Off",
      "source": "professional",
      "value": "On"
    },
    "overview": {
      "source": "professional",
      "value": "Overview"
    },
    "owned": {
      "source": "professional",
      "value": "Owner: <%- name %>"
    },
    "parentTeam": {
      "source": "professional",
      "value": "Parent Team"
    },
    "partOfTotal": {
      "source": "professional",
      "value": "<%- part %> of <%- total %>"
    },
    "percent": {
      "source": "professional",
      "value": "%"
    },
    "performance": {
      "source": "professional",
      "value": "Performance"
    },
    "print": {
      "source": "professional",
      "value": "Print"
    },
    "printForm": {
      "source": "professional",
      "value": "Print Form"
    },
    "processing": {
      "context": "To indicate a SCORM upload is being processed by the backend.",
      "source": "professional",
      "value": "Processing"
    },
    "progress": {
      "source": "professional",
      "value": "Progress"
    },
    "progressPercent": {
      "source": "professional",
      "value": "<%- percent %>% Progress"
    },
    "publish": {
      "source": "professional",
      "value": "Publish"
    },
    "readLess": {
      "source": "professional",
      "value": "Read less"
    },
    "readMore": {
      "source": "professional",
      "value": "Read more"
    },
    "relaunch": {
      "source": "professional",
      "value": "Relaunch"
    },
    "remove": {
      "source": "professional",
      "value": "Remove"
    },
    "required": {
      "context": "indicates that a form field must be completed",
      "source": "professional",
      "value": "required"
    },
    "reset": {
      "source": "professional",
      "value": "Reset"
    },
    "restore": {
      "source": "professional",
      "value": "Restore"
    },
    "retry": {
      "source": "professional",
      "value": "Retry"
    },
    "save": {
      "source": "professional",
      "value": "Save"
    },
    "saveAndAdd": {
      "source": "professional",
      "value": "Save & Add Another"
    },
    "saveChanges": {
      "source": "professional",
      "value": "Save Changes"
    },
    "scrollDown": {
      "source": "professional",
      "value": "Scroll"
    },
    "search": {
      "source": "professional",
      "value": "Search"
    },
    "selectAll": {
      "source": "professional",
      "value": "Select All"
    },
    "send": {
      "source": "professional",
      "value": "Send"
    },
    "settings": {
      "source": "professional",
      "value": "Settings"
    },
    "share": {
      "source": "professional",
      "value": "Share"
    },
    "showMore": {
      "source": "professional",
      "value": "Show more"
    },
    "showSelected": {
      "source": "professional",
      "value": "Show selected"
    },
    "start": {
      "source": "professional",
      "value": "Start"
    },
    "starts": {
      "source": "professional",
      "value": "Starts"
    },
    "success": {
      "source": "professional",
      "value": "Success!"
    },
    "title": {
      "source": "professional",
      "value": "Title"
    },
    "to": {
      "source": "professional",
      "value": "to"
    },
    "total": {
      "source": "professional",
      "value": "Total"
    },
    "true": {
      "context": "boolean true",
      "source": "professional",
      "value": "True"
    },
    "tryAgain": {
      "source": "professional",
      "value": "Try Again"
    },
    "type": {
      "context": "Category, not keyboard",
      "source": "professional",
      "value": "Type"
    },
    "update": {
      "source": "professional",
      "value": "Update"
    },
    "upload": {
      "file": {
        "browse": {
          "source": "professional",
          "value": "Browse"
        },
        "delete": {
          "source": "professional",
          "value": "Remove"
        },
        "dropFile": {
          "source": "professional",
          "value": "Drop file here"
        },
        "error": {
          "source": "professional",
          "value": "Error Uploading File"
        },
        "label": {
          "image": {
            "source": "professional",
            "value": "Add an Image:"
          }
        },
        "or": {
          "source": "professional",
          "value": "or"
        },
        "support": {
          "image": {
            "source": "professional",
            "value": "Supported file type: .gif, .jpeg, .png"
          }
        }
      },
      "inProgress": {
        "source": "professional",
        "value": "Uploading..."
      },
      "progressStatus": {
        "source": "professional",
        "value": "Uploading... <%- percent %>%"
      },
      "uploadData": {
        "source": "professional",
        "value": "Upload Data"
      }
    },
    "url": {
      "source": "professional",
      "value": "URL"
    },
    "users": {
      "source": "professional",
      "value": "Users"
    },
    "view": {
      "source": "professional",
      "value": "View"
    },
    "viewAll": {
      "source": "professional",
      "value": "View all"
    },
    "viewLastAttempt": {
      "source": "professional",
      "value": "View Last Attempt"
    },
    "viewLess": {
      "source": "professional",
      "value": "View less"
    },
    "viewMore": {
      "source": "professional",
      "value": "View more"
    },
    "warning": {
      "source": "professional",
      "value": "Warning!"
    },
    "yes": {
      "source": "professional",
      "value": "Yes"
    }
  },
  "groupedFilterableSelector": {
    "moreText": {
      "groupFilters": {
        "source": "professional",
        "value": "Load more groups..."
      },
      "publicFilters": {
        "source": "professional",
        "value": "Load more shared filters..."
      },
      "taskAssignments": {
        "groupFilters": {
          "source": "professional",
          "value": "View More Teams"
        },
        "publicFilters": {
          "source": "professional",
          "value": "View More Filters"
        },
        "teamFilters": {
          "source": "professional",
          "value": "View More Teams"
        },
        "userFilters": {
          "source": "professional",
          "value": "View More Filters"
        }
      },
      "teamFilters": {
        "source": "professional",
        "value": "Load more teams..."
      },
      "userFilters": {
        "source": "professional",
        "value": "Load more custom filters..."
      }
    }
  },
  "groups": {
    "add": {
      "source": "professional",
      "value": "Add Group"
    },
    "header": {
      "source": "professional",
      "value": "Groups"
    },
    "selectAllAria": {
      "source": "professional",
      "value": "Select all groups"
    }
  },
  "header": {
    "logo": {
      "alt": {
        "context": "Alternative text for the main brand",
        "source": "professional",
        "value": "Axonify"
      },
      "aria": {
        "context": "Text for screen readers to announce when focussed on the header logo image that is clickable and routes the user to the home page when clicked",
        "source": "professional",
        "value": "Home logo link. Click to navigate to the landing page."
      }
    },
    "navMenuButtonLabel": {
      "source": "professional",
      "value": "Navigation menu"
    }
  },
  "hub": {
    "activitiesCard": {
      "itemsCompleted": {
        "context": "A count of items completed. (i.e. 2 items completed or 1 of 3 items completed)",
        "source": "professional",
        "value": "<%- number %> items completed"
      },
      "numAvailable": {
        "context": "3 available",
        "source": "professional",
        "value": "<%- number %> available"
      },
      "viewAllTrainingItems": {
        "context": "Aria label for View All button on daily training card.",
        "source": "professional",
        "value": "View All Training Items"
      }
    },
    "allTimeRewardPoints": {
      "source": "professional",
      "value": "All Time Reward Points"
    },
    "correctAnswerStreak": {
      "source": "professional",
      "value": "Correct Answer Streak"
    },
    "currentRewardPoints": {
      "source": "professional",
      "value": "Current Reward Points"
    },
    "emptyStats": {
      "source": "professional",
      "value": "No Stats Available"
    },
    "leaderboardPositionAllTime": {
      "source": "professional",
      "value": "Top Performers Rank - All Time"
    },
    "leaderboardPositionLast30Days": {
      "source": "professional",
      "value": "Top Performers Rank - Last 30 Days"
    },
    "leaderboardPositionMonthly": {
      "source": "professional",
      "value": "Top Performers Rank - <%- currentMonth %>"
    },
    "trainingSessionsLast30Days": {
      "source": "professional",
      "value": "Training Sessions Last 30 Days"
    },
    "viewPath": {
      "source": "professional",
      "value": "View Path"
    },
    "viewPathAriaLabel": {
      "context": "View Path aria label string. Ex: View Grow, Perform, Succeed (GPS) at Axonify path. ",
      "source": "professional",
      "value": "View <%- programName %> path"
    }
  },
  "imports": {
    "audit": {
      "title": {
        "source": "professional",
        "value": "Audit Log"
      }
    },
    "error": {
      "error_message": {
        "source": "professional",
        "value": "Error Message"
      },
      "error_type": {
        "ABORTED": {
          "source": "professional",
          "value": "Canceled"
        },
        "COMPLETE": {
          "source": "professional",
          "value": "Complete"
        },
        "ERROR": {
          "source": "professional",
          "value": "Error"
        },
        "PENDING": {
          "source": "professional",
          "value": "Pending"
        },
        "PENDING_REVERT": {
          "source": "professional",
          "value": "Pending Revert"
        },
        "READY": {
          "source": "professional",
          "value": "Ready"
        },
        "REVERTED": {
          "source": "professional",
          "value": "Reverted"
        },
        "REVERT_ERROR": {
          "source": "professional",
          "value": "Revert Error"
        }
      },
      "import_errors": {
        "source": "professional",
        "value": "Import Errors"
      },
      "row": {
        "source": "professional",
        "value": "Row Number"
      },
      "view_errors": {
        "source": "professional",
        "value": "View Import Errors"
      }
    },
    "result": {
      "rows_failed": {
        "source": "professional",
        "value": "Failed Rows: <%- rows %>"
      },
      "rows_skipped": {
        "source": "professional",
        "value": "Skipped Rows: <%- rows %>"
      },
      "rows_successful": {
        "source": "professional",
        "value": "Successful Rows: <%- rows %> "
      },
      "rows_unchanged": {
        "context": "# of rows that didn't have any data updated during the data import",
        "source": "professional",
        "value": "Unchanged Rows: <%- rows %>"
      }
    },
    "supportUsername": {
      "context": "Axonify technical support (don't translate Axonify)",
      "source": "professional",
      "value": "Axonify Support"
    }
  },
  "interval": {
    "day": {
      "context": "Daily interval",
      "source": "professional",
      "value": "Day"
    },
    "month": {
      "context": "Monthly interval",
      "source": "professional",
      "value": "Month"
    },
    "week": {
      "context": "Weekly interval",
      "source": "professional",
      "value": "Week"
    }
  },
  "languages": {
    "AR": {
      "source": "professional",
      "value": "Arabic"
    },
    "BE": {
      "source": "professional",
      "value": "Belarusian"
    },
    "BG": {
      "source": "professional",
      "value": "Bulgarian"
    },
    "BS": {
      "source": "professional",
      "value": "Bosnian"
    },
    "CS": {
      "source": "professional",
      "value": "Czech"
    },
    "DA": {
      "source": "professional",
      "value": "Danish"
    },
    "DE": {
      "source": "professional",
      "value": "German"
    },
    "EL": {
      "source": "professional",
      "value": "Greek"
    },
    "EN": {
      "source": "professional",
      "value": "English"
    },
    "ENAU": {
      "source": "professional",
      "value": "English (Australia)"
    },
    "ENGB": {
      "source": "professional",
      "value": "English (UK)"
    },
    "ES": {
      "source": "professional",
      "value": "Spanish (North America)"
    },
    "ESES": {
      "source": "professional",
      "value": "Spanish (Spain)"
    },
    "ET": {
      "source": "professional",
      "value": "Estonian"
    },
    "FA": {
      "source": "professional",
      "value": "Farsi"
    },
    "FI": {
      "source": "professional",
      "value": "Finnish"
    },
    "FL": {
      "source": "professional",
      "value": "Filipino"
    },
    "FR": {
      "source": "professional",
      "value": "French"
    },
    "FRFR": {
      "source": "professional",
      "value": "French (France)"
    },
    "HE": {
      "source": "professional",
      "value": "Hebrew"
    },
    "HI": {
      "source": "professional",
      "value": "Hindi"
    },
    "HR": {
      "source": "professional",
      "value": "Croatian"
    },
    "HT": {
      "source": "professional",
      "value": "Haitian Creole"
    },
    "HU": {
      "source": "professional",
      "value": "Hungarian"
    },
    "HY": {
      "source": "professional",
      "value": "Armenian"
    },
    "ID": {
      "source": "professional",
      "value": "Indonesian"
    },
    "IT": {
      "source": "professional",
      "value": "Italian"
    },
    "JA": {
      "source": "professional",
      "value": "Japanese"
    },
    "KM": {
      "source": "professional",
      "value": "Cambodian"
    },
    "KO": {
      "source": "professional",
      "value": "Korean"
    },
    "LO": {
      "source": "professional",
      "value": "Lao"
    },
    "LT": {
      "source": "professional",
      "value": "Lithuanian"
    },
    "LV": {
      "source": "professional",
      "value": "Latvian"
    },
    "MK": {
      "source": "professional",
      "value": "Macedonian"
    },
    "MS": {
      "source": "professional",
      "value": "Malaysian"
    },
    "MY": {
      "source": "professional",
      "value": "Burmese"
    },
    "MYHA": {
      "source": "professional",
      "value": "Hakha Chin"
    },
    "NE": {
      "source": "professional",
      "value": "Nepali"
    },
    "NL": {
      "source": "professional",
      "value": "Dutch"
    },
    "NO": {
      "source": "professional",
      "value": "Norwegian"
    },
    "PA": {
      "source": "professional",
      "value": "Punjabi (Gurmukhi)"
    },
    "PL": {
      "source": "professional",
      "value": "Polish"
    },
    "PT": {
      "source": "professional",
      "value": "Portuguese (Brazil)"
    },
    "PTPT": {
      "source": "professional",
      "value": "Portuguese (Portugal)"
    },
    "RO": {
      "source": "professional",
      "value": "Romanian"
    },
    "ROMD": {
      "source": "professional",
      "value": "Moldovan"
    },
    "RU": {
      "source": "professional",
      "value": "Russian"
    },
    "SK": {
      "source": "professional",
      "value": "Slovakian"
    },
    "SL": {
      "source": "professional",
      "value": "Slovenian"
    },
    "SO": {
      "context": "language name",
      "source": "professional",
      "value": "Somali"
    },
    "SQ": {
      "source": "professional",
      "value": "Albanian"
    },
    "SR": {
      "source": "professional",
      "value": "Serbian (Cyrillic)"
    },
    "SRLT": {
      "source": "professional",
      "value": "Serbian (Latin)"
    },
    "SV": {
      "source": "professional",
      "value": "Swedish"
    },
    "SW": {
      "source": "professional",
      "value": "Swahili"
    },
    "TE": {
      "source": "professional",
      "value": "Telugu"
    },
    "TH": {
      "source": "professional",
      "value": "Thai"
    },
    "TR": {
      "source": "professional",
      "value": "Turkish"
    },
    "UK": {
      "source": "professional",
      "value": "Ukrainian"
    },
    "UR": {
      "source": "professional",
      "value": "Urdu"
    },
    "VI": {
      "source": "professional",
      "value": "Vietnamese"
    },
    "XX": {
      "source": "professional",
      "value": "Unknown"
    },
    "ZH": {
      "source": "professional",
      "value": "Chinese (Simplified)"
    },
    "ZHHK": {
      "source": "professional",
      "value": "Chinese (Traditional Hong Kong)"
    },
    "ZHTW": {
      "source": "professional",
      "value": "Chinese (Traditional Taiwan)"
    }
  },
  "learnerTrainingPage": {
    "search": {
      "paths": {
        "source": "professional",
        "value": "Filter Paths"
      }
    },
    "tab": {
      "path": {
        "completed": {
          "source": "professional",
          "value": "Completed"
        },
        "emptyCompleted": {
          "source": "professional",
          "value": "You have no completed paths."
        },
        "emptyInprogress": {
          "context": "Empty state for a users in progress paths",
          "source": "professional",
          "value": "You have no in progress paths."
        },
        "inProgress": {
          "source": "professional",
          "value": "In Progress"
        }
      }
    },
    "tabTitle": {
      "path": {
        "source": "professional",
        "value": "Paths"
      }
    }
  },
  "leftPaneSelector": {
    "ariaLabel": {
      "context": "Aria label for left panel of the three pane layout page.",
      "source": "professional",
      "value": "Left panel that contains list of items."
    }
  },
  "logErrors": {
    "maintenanceError": {
      "source": "professional",
      "value": "Axonify is Undergoing Maintenance"
    },
    "unknownServerError": {
      "source": "professional",
      "value": "Unknown Server Error"
    }
  },
  "login": {
    "agreement": {
      "agreeButton": {
        "source": "professional",
        "value": "I agree"
      },
      "agreeCheckbox": {
        "source": "professional",
        "value": "I have read and accept the user agreement"
      },
      "agreeHint": {
        "source": "professional",
        "value": "Check the user agreement checkbox to activate this button"
      },
      "failure": {
        "source": "professional",
        "value": "There was an error while reviewing user agreements. Skipping for now; you may be prompted in the future."
      },
      "footer": {
        "source": "professional",
        "value": "Footer"
      },
      "sucess": {
        "source": "professional",
        "value": "Your acceptance of the user agreement has been recorded."
      },
      "title": {
        "source": "professional",
        "value": "User Agreement"
      }
    },
    "authenticating": {
      "source": "professional",
      "value": "You are being logged into the platform. Please wait..."
    },
    "backToLogin": {
      "source": "professional",
      "value": "Back to login"
    },
    "cookiesRequired": {
      "source": "professional",
      "value": "Cookies are required!"
    },
    "cookiesWarningMessage": {
      "source": "professional",
      "value": "Oh no! It seems cookies are currently disabled in your browser.\n                Please enable them for this URL and Try Again to access the application."
    },
    "disclaimer": {
      "source": "professional",
      "value": ""
    },
    "enterPassword": {
      "source": "professional",
      "value": "Enter Password"
    },
    "enterUsername": {
      "source": "professional",
      "value": "Enter Username"
    },
    "forgotPassword": {
      "answers": {
        "inputLabel": {
          "source": "professional",
          "value": "Answer is case-sensitive"
        },
        "subTitle": {
          "source": "professional",
          "value": "Only you would know the answer"
        },
        "title": {
          "source": "professional",
          "value": "Here's a security question"
        }
      },
      "emailSent": {
        "furtherInformation": {
          "source": "professional",
          "value": "if the username <%= username %> exists in our system, we've sent an email to the linked address.  You may need to check your spam folder."
        },
        "subTitle": {
          "source": "professional",
          "value": "It has what you need"
        },
        "successBody": {
          "source": "professional",
          "value": "If you do not receive an email, you may try again or contact support."
        },
        "title": {
          "source": "professional",
          "value": "Check your email"
        }
      },
      "flowOptions": {
        "email": {
          "source": "professional",
          "value": "Send an email to the address that is connected to my account"
        },
        "recovery_questions": {
          "source": "professional",
          "value": "Show me a security question"
        },
        "title": {
          "source": "professional",
          "value": "How would you like to reset your password?"
        }
      },
      "genericError": {
        "source": "professional",
        "value": "There was a problem processing your request. Please contact an Administrator for assistance."
      },
      "incorrectAnswers": {
        "flashMessage": {
          "source": "professional",
          "value": "You have been locked out.  Try again in 15 minutes."
        },
        "message": {
          "source": "professional",
          "value": "To keep the account secure, we have temporarily locked <%= username %> since the security questions were answered incorrectly.  You can try again in 15 minutes."
        },
        "subTitle": {
          "source": "professional",
          "value": "You can try again in 15 minutes"
        },
        "title": {
          "source": "professional",
          "value": "We were unable to identify you"
        }
      },
      "inputEmptyError": {
        "source": "professional",
        "value": "Please enter your username"
      },
      "inputLabel": {
        "source": "professional",
        "value": "Start by entering your username"
      },
      "inputSubtitle": {
        "source": "professional",
        "value": "Let's get you a new one"
      },
      "inputTitle": {
        "source": "professional",
        "value": "Forgot your password?"
      },
      "recaptchaInvalid": {
        "source": "professional",
        "value": "Please successfully complete the recaptcha"
      },
      "securityQuestions": {
        "incorrectAnswer": {
          "source": "professional",
          "value": "The answer is incorrect."
        },
        "invalidToken": {
          "source": "professional",
          "value": "Your session has timed out.  Please try again."
        }
      }
    },
    "forgotUsername": {
      "inputEmail": {
        "source": "professional",
        "value": "Enter Email Address"
      },
      "inputLabel": {
        "source": "professional",
        "value": "Start by entering the email connected to your account:"
      },
      "inputSubtitle": {
        "source": "professional",
        "value": "We'll send you a refresher"
      },
      "inputTitle": {
        "source": "professional",
        "value": "Forgot your username?"
      },
      "invalidEmail": {
        "source": "professional",
        "value": "Submission must be in email format: example@example.com"
      },
      "languageSelector": {
        "source": "professional",
        "value": "Language Selector"
      },
      "successBody": {
        "source": "professional",
        "value": "if the email <%= address %> exists in our system, we've sent an email to that address. You may need to check your spam folder"
      },
      "successFurtherInformation": {
        "source": "professional",
        "value": "If you do not receive an email, you may try again or contact support"
      },
      "successSubtitle": {
        "source": "professional",
        "value": "It has what you need"
      },
      "successTitle": {
        "source": "professional",
        "value": "Check your email"
      }
    },
    "forgotpassword": {
      "source": "professional",
      "value": "Forgot Password?"
    },
    "forgotusername": {
      "source": "professional",
      "value": "Forgot Username?"
    },
    "googleMobileOAuthWarning": {
      "source": "professional",
      "value": "'<%= oauthProvider %>' is changing.  Please upgrade to the latest version of the Axonify App for uninterrupted service."
    },
    "greeting": {
      "source": "professional",
      "value": "Hi there,"
    },
    "helpEmailAndPhone": {
      "source": "professional",
      "value": "For support contact <%= helpEmail %> or <%= helpPhone %>"
    },
    "helpEmailOrPhone": {
      "source": "professional",
      "value": "For support contact <%= helpEmailOrPhone %>"
    },
    "hidePassword": {
      "source": "professional",
      "value": "Hide password"
    },
    "invalidCredentials": {
      "source": "professional",
      "value": "Invalid username or password."
    },
    "loginbtn": {
      "source": "professional",
      "value": "Login"
    },
    "loginmsg": {
      "source": "professional",
      "value": "Sign In"
    },
    "mfa": {
      "mfaauthcode": {
        "source": "professional",
        "value": "Authentication Code"
      },
      "mfainput": {
        "source": "professional",
        "value": "Enter Code from Authenticator"
      },
      "rememberMe": {
        "context": "Remember me for 30 days",
        "source": "professional",
        "value": "Remember me for <%- days %> days"
      },
      "verify": {
        "source": "professional",
        "value": "Verify"
      }
    },
    "password": {
      "source": "professional",
      "value": "Password"
    },
    "passwordReset": {
      "invalidToken": {
        "subtitle": {
          "source": "professional",
          "value": "You can request a new one"
        },
        "title": {
          "source": "professional",
          "value": "This link has expired"
        },
        "tryAgain": {
          "source": "professional",
          "value": "Try Again"
        }
      },
      "reset": {
        "confirmPassword": {
          "source": "professional",
          "value": "Confirm new password"
        },
        "failure": {
          "source": "professional",
          "value": "An error occurred while resetting your password.  Please try again or contact an Adminstrator"
        },
        "invalidcomplexpasswordgroups": {
          "source": "professional",
          "value": "Weak Password, must include characters from <%- minNumberOfCharacterGroups %> of the following groups: uppercase letter, lowercase letter, number, or symbol."
        },
        "invalidcomplexpasswordshort": {
          "source": "professional",
          "value": "Short Password, must be at least <%- minPasswordLength %> characters."
        },
        "invalidpassword": {
          "source": "professional",
          "value": "Password should be at least <%- minPasswordLength %> characters."
        },
        "newPassword": {
          "source": "professional",
          "value": "Enter new password"
        },
        "passwordmismatch": {
          "source": "professional",
          "value": "Passwords don't match."
        },
        "subTitle": {
          "source": "professional",
          "value": "All that's left is to create a new password"
        },
        "success": {
          "source": "professional",
          "value": "Your password was successfully reset"
        },
        "title": {
          "source": "professional",
          "value": "Almost there!"
        }
      }
    },
    "privacyPolicy": {
      "source": "professional",
      "value": "Privacy Policy"
    },
    "resetapp": {
      "source": "professional",
      "value": "Change Workplace URL"
    },
    "showPassword": {
      "source": "professional",
      "value": "Show password"
    },
    "signinSAML": {
      "default": {
        "source": "professional",
        "value": "Sign In to Start"
      }
    },
    "submit": {
      "source": "professional",
      "value": "Submit"
    },
    "upgradeCallToAction": {
      "source": "professional",
      "value": "Upgrade Now"
    },
    "username": {
      "source": "professional",
      "value": "Username"
    },
    "withOAuthProvider": {
      "source": "professional",
      "value": "Login with <%- oauthProvider %>"
    }
  },
  "manager": {
    "compare": {
      "listHeaders": {
        "frequency": {
          "source": "professional",
          "value": "Frequency"
        },
        "knowledge": {
          "source": "professional",
          "value": "Knowledge"
        },
        "participation": {
          "source": "professional",
          "value": "Participation"
        }
      }
    },
    "metrics": {
      "needsCoaching": {
        "meta": {
          "source": "professional",
          "value": "Users Consistently Under <%- percentage %>%"
        },
        "title": {
          "source": "professional",
          "value": "Needs Coaching"
        }
      },
      "nonParticipants": {
        "meta": {
          "source": "professional",
          "value": "Last 7 Days"
        },
        "title": {
          "source": "professional",
          "value": "Non-Participants"
        }
      },
      "overdueCertifications": {
        "meta": {
          "source": "professional",
          "value": "Users Past Due"
        },
        "title": {
          "source": "professional",
          "value": "Overdue Certifications"
        }
      }
    }
  },
  "menu": {
    "apps": {
      "admin": {
        "source": "professional",
        "value": "<%= t('appNames.admin') %>"
      },
      "chat": {
        "directMessages": {
          "context": "name for the direct messages feature",
          "source": "professional",
          "value": "Direct Messages"
        },
        "teamBased": {
          "context": "name for the team chat feature",
          "source": "professional",
          "value": "Team Chat"
        }
      },
      "contentexchange": {
        "source": "professional",
        "value": "<%= t('contentExchange') %>"
      },
      "editor": {
        "context": "The non-translated value for the Editor app (formerly Discover)",
        "source": "professional",
        "value": "<%= t('appNames.editor') %>"
      },
      "infoLink": {
        "source": "professional",
        "value": "<%= t('appNames.knowledge') %>"
      },
      "teamLink": {
        "source": "professional",
        "value": "<%= t('appNames.manager') %>"
      },
      "training": {
        "source": "professional",
        "value": "<%= t('appNames.training') %>"
      }
    },
    "main": {
      "source": "professional",
      "value": "Main"
    },
    "moreButton": {
      "source": "professional",
      "value": "More"
    }
  },
  "messages": {
    "Location": {
      "POST": {
        "3054": {
          "source": "professional",
          "value": "The code you have picked for this team is in use for another team."
        }
      },
      "PUT": {
        "3054": {
          "source": "professional",
          "value": "The code you have picked for this team is in use for another team."
        }
      }
    },
    "notAvailable": {
      "source": "professional",
      "value": "Not Available"
    }
  },
  "metrics": {
    "listErrors": {
      "listTooLarge": {
        "source": "professional",
        "value": "The list you are trying to view is too large."
      }
    },
    "milestones": {
      "categories": {
        "started": {
          "source": "professional",
          "value": "Started"
        }
      }
    }
  },
  "mixins": {
    "emptyrow": {
      "source": "professional",
      "value": "No items available"
    },
    "loadingrow": {
      "source": "professional",
      "value": "Loading..."
    }
  },
  "mui": {
    "autocomplete": {
      "clearText": {
        "source": "professional",
        "value": "Clear"
      },
      "loadingText": {
        "source": "professional",
        "value": "Loading..."
      },
      "noOptionsText": {
        "source": "professional",
        "value": "No options available"
      },
      "openText": {
        "context": "This is a general string for open feedback items (or other uses of an 'Open' label)",
        "source": "professional",
        "value": "Open"
      }
    },
    "breadcrumbs": {
      "expandText": {
        "context": "Aria label for a button that expands to show all breadcrumbs when some are hidden.",
        "source": "professional",
        "value": "Show path"
      }
    },
    "datepicker": {
      "chooseDate": {
        "source": "professional",
        "value": "Choose date"
      },
      "chooseDateAndSelectedDate": {
        "context": "Aria-label telling the user to choose a date while notifying them of the date already selected",
        "source": "professional",
        "value": "Choose date, selected date is <%- date %>"
      },
      "openCalendarView": {
        "context": "Aria-label to switch to the year view when selecting a date",
        "source": "professional",
        "value": "Calendar view is open, switch to year view"
      },
      "openNextView": {
        "source": "professional",
        "value": "Open next view"
      },
      "openPreviousView": {
        "source": "professional",
        "value": "Open previous view"
      },
      "openYearView": {
        "context": "Aria-label to switch to the calendar view when selecting a date",
        "source": "professional",
        "value": "Year view is open, switch to calendar view"
      },
      "pickDate": {
        "source": "professional",
        "value": "Pick date"
      },
      "selectDate": {
        "context": "professional",
        "source": "professional",
        "value": "Select a date"
      },
      "weekNumber": {
        "source": "professional",
        "value": "Week number"
      }
    },
    "datetimepicker": {
      "hours": {
        "context": "The selected hours on a clock",
        "source": "professional",
        "value": "<%- hours %> hours"
      },
      "minutes": {
        "context": "The minutes selected on a clock (eg. 55 minutes)",
        "source": "professional",
        "value": "<%- minutes %> minutes"
      },
      "noSelectedTime": {
        "source": "professional",
        "value": "No time selected"
      },
      "pickTime": {
        "source": "professional",
        "value": "Select time"
      },
      "selectDateTime": {
        "context": "Accessible title telling users to select a date and time",
        "source": "professional",
        "value": "Select date and time"
      },
      "selectView": {
        "context": "Aria-label for the views that open in the date time picker; eg. Select hours, Select minutes, etc.",
        "source": "professional",
        "value": "Select <%- view %>"
      },
      "selectedTime": {
        "context": "Reads out the selected time, eg. Selected time is 4:22pm",
        "source": "professional",
        "value": "Selected time is <%- time %>"
      }
    },
    "general": {
      "closeText": {
        "source": "professional",
        "value": "Close"
      }
    },
    "pagination": {
      "ariaLabel": {
        "context": "Aria label for the navigation buttons of a paged list component",
        "source": "professional",
        "value": "Pagination navigation"
      },
      "firstPage": {
        "context": "a button used to navigate to the first page of results",
        "source": "professional",
        "value": "Navigate to the first page of results"
      },
      "labelDisplayedRows": {
        "context": "An aria label to communicate the number of result rows in a table component currently displayed relative to the total. For example, '5 - 10 of 100'",
        "source": "professional",
        "value": "<%- from %> - <%- to %> of <%- count %>"
      },
      "labelDisplayedRowsAlternate": {
        "context": "An aria label used to communicate the number of rows currently displayed in a table component when the total is unavailable. For example, '5 - 10 of more than 10'",
        "source": "professional",
        "value": "<%- from %> - <%- to %> of more than <%- to %>"
      },
      "labelRowsPerPage": {
        "context": "Aria label for displayed rows per page in a paged table component",
        "source": "professional",
        "value": "Rows per page:"
      },
      "lastPage": {
        "context": "a button used to navigate to the last page of results",
        "source": "professional",
        "value": "Navigate to the last page of results"
      },
      "nextPage": {
        "context": "a button used to navigate to the next page of results",
        "source": "professional",
        "value": "Navigate to the next page of results"
      },
      "previousPage": {
        "context": "a button used to navigate to the previous page of results",
        "source": "professional",
        "value": "Navigate to the previous page of results"
      },
      "selectPage": {
        "context": "user clicks a numbered button with value 'i' to navigate to the respective page of results",
        "source": "professional",
        "value": "Navigate to page <%- i %> of results"
      }
    },
    "rating": {
      "emptyLabelText": {
        "context": "Aria label for a star rating component when no stars have been selected",
        "source": "professional",
        "value": "No rating selected"
      },
      "labelTextPlural": {
        "context": "Aria label for a star rating component when more than one star is selected",
        "source": "professional",
        "value": "<%- value %> Stars"
      },
      "labelTextSingular": {
        "context": "Aria label for a star rating component when one star is selected",
        "source": "professional",
        "value": "<%- value %> Star"
      }
    }
  },
  "multiFilter": {
    "btnLabel": {
      "source": "professional",
      "value": "Advanced"
    },
    "clearFilter": {
      "source": "professional",
      "value": "Clear"
    },
    "createNew": {
      "context": "Button used to create a new filter",
      "source": "professional",
      "value": "Create New Filter"
    },
    "default": {
      "source": "professional",
      "value": "Default"
    },
    "defaultFilterSearchPrompt": {
      "source": "professional",
      "value": "Find a team or filter"
    },
    "defaultSearchPrompt": {
      "source": "professional",
      "value": "Use quick filter to filter on multiple items and create new filters."
    },
    "editFilter": {
      "deleteConfirm": {
        "source": "professional",
        "value": "Are you sure you want to delete filter: <br/><u><span class='qa-delete-multi-filter--name'><%- filterName %></span></u>?<br/><br/>All data will be lost."
      },
      "modify": {
        "source": "professional",
        "value": "Modify"
      }
    },
    "empty": {
      "context": "Message to display when a filter does not exist",
      "source": "professional",
      "value": "We couldn't find a team or filter with that name."
    },
    "emptyText": {
      "source": "professional",
      "value": "No options available"
    },
    "errors": {
      "3002": {
        "source": "professional",
        "value": "The group with name \"<%- name %>\" already exists."
      },
      "3007": {
        "source": "professional",
        "value": "Can't delete group \"<%- name %>\" because it's being used in programs or data exports."
      },
      "minSelected": {
        "source": "professional",
        "value": "At least 1 item must be selected."
      }
    },
    "filterGroups": {
      "mine": {
        "context": "The label for the saved MultiFilters created by the user",
        "source": "professional",
        "value": "Filters created by me"
      },
      "shared": {
        "source": "professional",
        "value": "Filters shared with me"
      },
      "team": {
        "source": "professional",
        "value": "Teams"
      }
    },
    "itemsSelected": {
      "source": "professional",
      "value": "items selected"
    },
    "myFilterName": {
      "source": "professional",
      "value": "My Filter"
    },
    "noQuickFilters": {
      "source": "professional",
      "value": "Please save your quick filter or select an existing filter from the list."
    },
    "quickFilterName": {
      "source": "professional",
      "value": "Quick Filter"
    },
    "quickFilterSearchPrompt": {
      "source": "professional",
      "value": "Search for items..."
    },
    "quickFilterSelector": {
      "dateCondition": {
        "source": "professional",
        "value": "Date Condition"
      },
      "departments": {
        "source": "professional",
        "value": "Departments"
      },
      "groupAreasOfInterest": {
        "source": "professional",
        "value": "Areas of Interest"
      },
      "groupTeamAttributes": {
        "context": "Quick Filter selecting team attributes",
        "source": "professional",
        "value": "Team Attributes"
      },
      "jobTitles": {
        "source": "professional",
        "value": "Job Titles"
      },
      "labels": {
        "createFilter": {
          "context": "Title for creating a filter",
          "source": "professional",
          "value": "Create a Filter"
        },
        "filterName": {
          "context": "Label for Filter Name input",
          "source": "professional",
          "value": "Filter Name"
        },
        "modifyFilter": {
          "context": "Title for modifying a quick filter",
          "source": "professional",
          "value": "Modify Filter"
        },
        "searchAttributes": {
          "context": "Label for Searching attributes for filter",
          "source": "professional",
          "value": "Search Attributes"
        }
      },
      "linesOfBusiness": {
        "source": "professional",
        "value": "Lines of Business"
      },
      "locationTypes": {
        "source": "professional",
        "value": "Team Types"
      },
      "locations": {
        "source": "professional",
        "value": "Teams"
      },
      "makePublic": {
        "source": "professional",
        "value": "Make Public"
      },
      "namePlaceholder": {
        "source": "professional",
        "value": "Enter a name for the filter..."
      },
      "noFilterNameError": {
        "source": "professional",
        "value": "A filter name is required"
      },
      "numSelected": {
        "source": "professional",
        "value": "Selected items: <%- numSelected %>"
      },
      "prefix": {
        "team": {
          "context": "This filter targets teams",
          "source": "professional",
          "value": "Team"
        },
        "user": {
          "context": "This filter targets users",
          "source": "professional",
          "value": "User"
        }
      },
      "saveAndApply": {
        "source": "professional",
        "value": "Save & Apply"
      },
      "saveLabel": {
        "source": "professional",
        "value": "Create a new filter?"
      },
      "showSelected": {
        "source": "professional",
        "value": "Show selected"
      },
      "teamOnlyTitle": {
        "source": "professional",
        "value": "Select Team and Type"
      }
    },
    "searchFilterPrompt": {
      "source": "professional",
      "value": "Select Filter"
    }
  },
  "multiLanguageFieldWrapper": {
    "translationErrorLabel": {
      "source": "professional",
      "value": "translations are invalid."
    },
    "translationInvalidLabel": {
      "context": "Text value for incomplete translations icon",
      "source": "professional",
      "value": "Translations are incomplete."
    },
    "translationValidLabel": {
      "source": "professional",
      "value": "translations are valid."
    }
  },
  "newTasks": {
    "accessibility": {
      "activityItemActionMenu": {
        "source": "professional",
        "value": "action menu for task activity item"
      },
      "managerUserTaskItem": {
        "source": "professional",
        "value": "View task instance for: <%- userName %>"
      }
    },
    "activityActions": {
      "deleteComment": {
        "source": "professional",
        "value": "Delete Comment"
      },
      "deleteCommentConfirmation": {
        "source": "professional",
        "value": "Delete Comment?"
      },
      "deleteMedia": {
        "source": "professional",
        "value": "Delete Photo"
      },
      "deleteMediaConfirmation": {
        "source": "professional",
        "value": "Delete Photo?"
      },
      "deletionWarning": {
        "source": "professional",
        "value": "This action can not be undone."
      }
    },
    "deletedComment": {
      "context": "Displays instead of comment text in task details when a comment has been deleted",
      "source": "professional",
      "value": "Comment Deleted"
    },
    "deletedMedia": {
      "context": "Displays on the task timeline instead of photo when a photo has been deleted",
      "source": "professional",
      "value": "Photo Deleted"
    },
    "everyoneCompleted": {
      "source": "professional",
      "value": "Everyone has completed this task"
    },
    "noOneCompleted": {
      "source": "professional",
      "value": "No one has completed this task"
    },
    "noTasks": {
      "context": "Message when there are no tasks",
      "source": "professional",
      "value": "Your task list is currently empty."
    },
    "noneCompleted": {
      "context": "Message when there are no tasks completed",
      "source": "professional",
      "value": "There are no completed tasks to show"
    },
    "tabs": {
      "activity": {
        "source": "professional",
        "value": "Activity"
      },
      "complete": {
        "context": "Displays the amount of completed tasks",
        "source": "professional",
        "value": "Complete"
      },
      "incomplete": {
        "context": "Displays the amount of incompleted tasks",
        "source": "professional",
        "value": "Incomplete"
      },
      "steps": {
        "context": "Tab label for Steps tab",
        "source": "professional",
        "value": "Steps"
      }
    },
    "taskTimeline": {
      "noActivity": {
        "context": "Displays in the Activity tab of the Task Details modal when there is no activity",
        "source": "professional",
        "value": "No activity to show yet"
      }
    }
  },
  "notifications": {
    "discoveryZoneLink": {
      "status": {
        "archived": {
          "context": "This error message is telling the user that the URL they are trying to use is part of a community that is archived. Archived communities are not available to be seen by anyone, and the user should use a different URL, or restore the community that is archived",
          "source": "professional",
          "value": "Linked item is part of an archived community. Cannot use."
        },
        "differentLanguage": {
          "source": "professional",
          "value": "Linked item is in a different language"
        },
        "expired": {
          "context": "The expiry timestamp",
          "source": "professional",
          "value": "Linked item expired on: <%- expired %>"
        },
        "inReview": {
          "source": "professional",
          "value": "Linked item is in-review. Cannot use."
        },
        "itemDeletedOrChanged": {
          "source": "professional",
          "value": "Linked item does not exist or has been deleted"
        },
        "itemNotFound": {
          "source": "professional",
          "value": "Item not found"
        },
        "published": {
          "source": "",
          "value": "Published:"
        },
        "reported": {
          "source": "professional",
          "value": "Linked item has been reported. Cannot use."
        },
        "scheduled": {
          "source": "professional",
          "value": "Scheduled to publish on:"
        }
      }
    }
  },
  "nudgeTasks": {
    "addPhoto": {
      "source": "professional",
      "value": "Add a photo to complete this task"
    },
    "assign": {
      "team": {
        "othersForOthers": {
          "context": "John Doe assigned Emma Smith as the task owner.",
          "source": "professional",
          "value": "<%= submittingUser %> assigned <%= assignedUser %> as the task owner."
        },
        "othersForSelf": {
          "context": "John Doe assigned you as the task owner.",
          "source": "professional",
          "value": "<%= submittingUser %> assigned you as the task owner."
        },
        "selfForOthers": {
          "context": "You assigned Emma Smith as the task owner.",
          "source": "professional",
          "value": "You assigned <%= assignedUser %> as the task owner."
        },
        "selfForSelf": {
          "source": "professional",
          "value": "You assigned yourself as the task owner."
        }
      }
    },
    "assignOwner": {
      "assignToMe": {
        "source": "professional",
        "value": "Assign to me"
      },
      "assigned": {
        "source": "professional",
        "value": "Assigned"
      },
      "modal": {
        "noRecipientsAvailable": {
          "source": "professional",
          "value": "No users found"
        },
        "searchForMoreResults": {
          "source": "professional",
          "value": "Can't find who you're looking for? Try searching for them."
        }
      },
      "people": {
        "source": "professional",
        "value": "People"
      },
      "removeOwner": {
        "source": "professional",
        "value": "Remove Owner"
      },
      "subtitle": {
        "source": "professional",
        "value": "The person responsible for this task."
      },
      "success": {
        "assignOwner": {
          "source": "professional",
          "value": "Task owner has been successfully updated."
        }
      },
      "title": {
        "assign": {
          "source": "professional",
          "value": "Assign owner"
        },
        "edit": {
          "source": "professional",
          "value": "Edit Owner"
        }
      }
    },
    "completeTask": {
      "source": "professional",
      "value": "Complete Task"
    },
    "create": {
      "backToTask": {
        "context": "Button text for a button that navigates back to the task creation form",
        "source": "professional",
        "value": "Back to Task"
      },
      "dueDateLabel": {
        "source": "professional",
        "value": "Due Date"
      },
      "repeats": {
        "source": "professional",
        "value": "Repeats"
      }
    },
    "createdBy": {
      "context": "Label specifying which user created the task, eg. Created by Bob Smith",
      "source": "professional",
      "value": "Created by <%= name %>"
    },
    "error": {
      "fetchTaskDetails": {
        "source": "professional",
        "value": "The task has been deleted or does not exist anymore."
      }
    },
    "imageTypes": {
      "source": "professional",
      "value": "Accepted file types: .jpg, .png"
    },
    "markCompleteButton": {
      "context": "Button label to mark a task as complete",
      "source": "professional",
      "value": "Mark as Complete"
    },
    "markCompleteWithSteps": {
      "context": "Button label to mark an entire task with steps as complete",
      "source": "professional",
      "value": "Mark Entire Task as Complete"
    },
    "markIncompleteDialog": {
      "confirm": {
        "source": "professional",
        "value": "Yes, Mark as Incomplete"
      },
      "message": {
        "source": "professional",
        "value": "This will change that status of the task from completed to incomplete."
      },
      "title": {
        "source": "professional",
        "value": "Are you sure you want to mark as incomplete?"
      }
    },
    "markedComplete": {
      "steps": {
        "team": {
          "otherPlural": {
            "context": "John Doe marked the entire task as complete for Development (1 of 10 Steps).",
            "source": "professional",
            "value": "<%= submittingUser %> marked the entire task as complete for <%= teamName %> (<%= completedSteps %> of <%= totalSteps %> Steps)."
          },
          "otherSingular": {
            "context": "John Doe marked the entire task as complete for Development (1 of 1 Step).",
            "source": "professional",
            "value": "<%= submittingUser %> marked the entire task as complete for <%= teamName %> (<%= completedSteps %> of 1 Step)."
          },
          "selfPlural": {
            "context": "You marked the entire task as complete for Development (1 of 10 Steps).",
            "source": "professional",
            "value": "You marked the entire task as complete for <%= teamName %> (<%= completedSteps %> of <%= totalSteps %> Steps)."
          },
          "selfSingular": {
            "context": "You marked the entire task as complete for Development (1 of 1 Step).",
            "source": "professional",
            "value": "You marked the entire task as complete for <%= teamName %> (<%= completedSteps %> of 1 Step)."
          }
        },
        "user": {
          "otherPlural": {
            "context": "John Doe marked the entire task as complete (1 of 10 Steps).",
            "source": "professional",
            "value": "<%= submittingUser %> marked the entire task as complete (<%= completedSteps %> of <%= totalSteps %> Steps)."
          },
          "otherSingular": {
            "context": "John Doe marked the entire task as complete (1 of 1 Step).",
            "source": "professional",
            "value": "<%= submittingUser %> marked the entire task as complete (<%= completedSteps %> of 1 Step)."
          },
          "selfPlural": {
            "context": "You marked the entire task as complete (1 of 10 Steps).",
            "source": "professional",
            "value": "You marked the entire task as complete (<%= completedSteps %> of <%= totalSteps %> Steps)."
          },
          "selfSingular": {
            "context": "You marked the entire task as complete (1 of 1 Step).",
            "source": "professional",
            "value": "You marked the entire task as complete (<%= completedSteps %> of 1 Step)."
          }
        }
      },
      "team": {
        "othersForOthers": {
          "context": "John Doe marked as complete for Emma Smith.",
          "source": "professional",
          "value": "<%= submittingUser %> marked as complete for <%= assignedUser %>."
        },
        "othersForSelf": {
          "context": "John Doe marked as complete for you.",
          "source": "professional",
          "value": "<%= submittingUser %> marked as complete for you."
        },
        "othersForTeam": {
          "context": "John Doe marked as complete for Development.",
          "source": "professional",
          "value": "<%= submittingUser %> marked as complete for <%= teamName %>."
        },
        "selfForOthers": {
          "context": "You marked as complete for Emma Smith.",
          "source": "professional",
          "value": "You marked as complete for <%= assignedUser %>."
        },
        "selfForSelf": {
          "source": "professional",
          "value": "You marked as complete for yourself."
        },
        "selfForTeam": {
          "context": "You marked as complete for Development.",
          "source": "professional",
          "value": "You marked as complete for <%= teamName %>."
        }
      },
      "user": {
        "other": {
          "context": "John Doe marked task as complete.",
          "source": "professional",
          "value": "<%= submittingUser %> marked as complete."
        },
        "self": {
          "source": "professional",
          "value": "You marked as complete."
        }
      }
    },
    "markedIncomplete": {
      "steps": {
        "team": {
          "other": {
            "context": "John Doe marked the entire task as incomplete for Development.",
            "source": "professional",
            "value": "<%= submittingUser %> marked the entire task as incomplete for <%= teamName %>."
          },
          "self": {
            "context": "You marked the entire task as incomplete for Development.",
            "source": "professional",
            "value": "You marked the entire task as incomplete for <%= teamName %>."
          }
        },
        "user": {
          "other": {
            "context": "John Doe marked the entire task as incomplete.",
            "source": "professional",
            "value": "<%= submittingUser %> marked the entire task as incomplete."
          },
          "self": {
            "source": "professional",
            "value": "You marked the entire task as incomplete."
          }
        }
      },
      "team": {
        "othersForOthers": {
          "context": "John Doe marked as incomplete for Jane Doe.",
          "source": "professional",
          "value": "<%= submittingUser %> marked as incomplete for <%= assignedUser %>."
        },
        "othersForSelf": {
          "context": "John Doe marked as incomplete for you.",
          "source": "professional",
          "value": "<%= submittingUser %> marked as incomplete for you."
        },
        "othersForTeam": {
          "context": "John Doe marked as incomplete for Development.",
          "source": "professional",
          "value": "<%= submittingUser %> marked as incomplete for <%= teamName %>."
        },
        "selfForOthers": {
          "context": "You marked as incomplete for John Doe.",
          "source": "professional",
          "value": "You marked as incomplete for <%= assignedUser %>."
        },
        "selfForSelf": {
          "source": "professional",
          "value": "You marked as incomplete for yourself."
        },
        "selfForTeam": {
          "context": "You marked as incomplete for <%= teamName %>.",
          "source": "professional",
          "value": "You marked as incomplete for <%= teamName %>."
        }
      },
      "user": {
        "other": {
          "context": "Jane Doe marked as incomplete.",
          "source": "professional",
          "value": "<%= submittingUser %> marked as incomplete."
        },
        "self": {
          "source": "professional",
          "value": "You marked as incomplete."
        }
      }
    },
    "markedStepComplete": {
      "other": {
        "context": "Jane Doe marked step as complete:",
        "source": "professional",
        "value": "<%= submittingUser %> marked step as complete:"
      },
      "self": {
        "source": "professional",
        "value": "You marked step as complete:"
      }
    },
    "markedStepIncomplete": {
      "other": {
        "context": "Jane Doe marked step as incomplete:",
        "source": "professional",
        "value": "<%= submittingUser %> marked step as incomplete:"
      },
      "self": {
        "source": "professional",
        "value": "You marked step as incomplete:"
      }
    },
    "photoUpload": {
      "maxFileSize": {
        "context": "Max file size: 15MB",
        "source": "professional",
        "value": "Max file size: <%= fileSize %>MB"
      }
    },
    "photoVerification": {
      "submit": {
        "source": "professional",
        "value": "Submit"
      }
    },
    "success": {
      "taskComplete": {
        "source": "professional",
        "value": "Task has been marked complete."
      },
      "taskIncomplete": {
        "source": "professional",
        "value": "Task has been marked incomplete."
      }
    },
    "timeline": {
      "imageLabel": {
        "context": "Aria label for an image that is expandable on click or keypress",
        "source": "professional",
        "value": "Timeline image. Click or press enter or space to expand"
      }
    },
    "unassign": {
      "team": {
        "othersForOthers": {
          "context": "John Doe removed Emma Smith as the task owner.",
          "source": "professional",
          "value": "<%= submittingUser %> removed <%= assignedUser %> as the task owner."
        },
        "othersForSelf": {
          "context": "John Doe removed you as the task owner.",
          "source": "professional",
          "value": "<%= submittingUser %> removed you as the task owner."
        },
        "selfForOthers": {
          "context": "You removed Emma Smith as the task owner.",
          "source": "professional",
          "value": "You removed <%= assignedUser %> as the task owner."
        },
        "selfForSelf": {
          "source": "professional",
          "value": "You removed yourself as the task owner."
        }
      }
    },
    "upload": {
      "source": "professional",
      "value": "Upload"
    },
    "uploadPhotoMarkComplete": {
      "source": "professional",
      "value": "Upload Photo & Mark as Complete"
    }
  },
  "observations": {
    "details": {
      "button": {
        "increment": {
          "context": "Increment button for team observation form",
          "source": "professional",
          "value": "Increment"
        }
      },
      "evaluationScore": {
        "complete": {
          "context": "Indicates the score the user achieved in their previous attempt of the Evaluation. (Example) Scored: 75%",
          "source": "professional",
          "value": "Scored: <%- evaluationScore %>%"
        },
        "pending": {
          "context": "Indicates the score the user needs to pass the evaluation. (Example) Passing Score: 75%",
          "source": "professional",
          "value": "Passing Score: <%- passingScore %>%"
        }
      },
      "labels": {
        "behaviorTemplate": {
          "source": "professional",
          "value": "Behavior Form Template:"
        },
        "created": {
          "context": "This is the label for the createDate field value on behaviour form templates (both for screen and print)",
          "source": "professional",
          "value": "Created On:"
        },
        "department": {
          "source": "professional",
          "value": "Department:"
        },
        "employeeId": {
          "source": "professional",
          "value": "Employee ID:"
        },
        "evaluationResults": {
          "context": "Section header for the results of an evaluation",
          "source": "professional",
          "value": "Evaluation Results"
        },
        "evaluationScore": {
          "context": "The label for the score (if any) of the currently displayed evaluation",
          "source": "professional",
          "value": "Evaluation Score"
        },
        "evaluator": {
          "context": "This is the name of the person giving the evaluation",
          "source": "professional",
          "value": "Evaluator:"
        },
        "fail": {
          "context": "The state that will be assigned to an evaluation that does not meet the minimum criteria",
          "source": "professional",
          "value": "Fail"
        },
        "formType": {
          "source": "professional",
          "value": "Behavior Form Type:"
        },
        "generalNotes": {
          "context": "Notes/comments left for the observation/evaluation as a whole, rather than in regards to a single behavior",
          "source": "professional",
          "value": "General Notes"
        },
        "jobTitle": {
          "source": "professional",
          "value": "Job Title:"
        },
        "notes": {
          "context": "Notes/comments left as extra detail about an observation/evaluation entry",
          "source": "professional",
          "value": "Notes:"
        },
        "observee": {
          "source": "professional",
          "value": "Employee:"
        },
        "observer": {
          "source": "professional",
          "value": "Observer:"
        },
        "pass": {
          "context": "The state that will be assigned to an evaluation that meets the minimum criteria",
          "source": "professional",
          "value": "Pass"
        },
        "status": {
          "context": "The label for the current status of an evaluation/observation (e.g. 'Pending', 'In Progress', 'Completed', etc.)",
          "source": "professional",
          "value": "Status"
        },
        "team": {
          "source": "professional",
          "value": "Team:"
        }
      }
    },
    "print": {
      "userBehaviourEvaluation": {
        "context": "This is the 'Type' value given for User Behavior forms to record Evaluations",
        "source": "professional",
        "value": "User Behavior Evaluation"
      },
      "userBehaviourVirtualEvaluation": {
        "source": "professional",
        "value": "User Behavior Virtual Evaluation"
      }
    }
  },
  "pagination": {
    "currentPage": {
      "context": "For accessibility, read out what page the user is currently on in the list",
      "source": "professional",
      "value": "Currently on page <%- i %>"
    },
    "firstPage": {
      "context": "a button used to navigate to the first page of results",
      "source": "professional",
      "value": "Navigate to the first page of results"
    },
    "lastPage": {
      "context": "a button used to navigate to the last page of results",
      "source": "professional",
      "value": "Navigate to the last page of results"
    },
    "nextPage": {
      "context": "a button used to navigate to the next page of results",
      "source": "professional",
      "value": "Navigate to the next page of results"
    },
    "previousPage": {
      "context": "a button used to navigate to the previous page of results",
      "source": "professional",
      "value": "Navigate to the previous page of results"
    },
    "selectPage": {
      "context": "user clicks a numbered button with value 'i' to navigate to the respective page of results",
      "source": "professional",
      "value": "Navigate to page <%- i %> of results"
    }
  },
  "pdf": {
    "noViewer": {
      "source": "professional",
      "value": "There was no PDF viewer available for this browser. Please install Adobe Reader or another PDF utility to view this file."
    },
    "openPdf": {
      "context": "Open PDF viewer",
      "source": "professional",
      "value": "Open PDF"
    }
  },
  "people": {
    "profile": {
      "avatar": {
        "context": "Alt text for user's profile image; eg. Profile avatar for user John Doe",
        "source": "professional",
        "value": "Profile avatar for user <%- userName %>"
      },
      "averageScore": {
        "source": "professional",
        "value": "Average Score"
      }
    }
  },
  "photoCrop": {
    "adjustPhoto": {
      "button": {
        "title": {
          "source": "professional",
          "value": "Change Image"
        }
      },
      "subTitle": {
        "source": "professional",
        "value": "Drag the square boundaries to change position and size"
      },
      "title": {
        "source": "professional",
        "value": "Crop Image"
      }
    },
    "choosePhoto": {
      "button": {
        "title": {
          "source": "professional",
          "value": "Add Image"
        }
      },
      "subTitle": {
        "source": "professional",
        "value": "You can upload a JPEG, GIF, or PNG file (File size limit is <%= maximumImageSize %> MB)."
      },
      "title": {
        "source": "professional",
        "value": "Choose Image"
      }
    },
    "cropError": {
      "context": "Error when cropping an image because the crop is out of bounds of the image area. This error refers to the cropping tool.",
      "source": "professional",
      "value": "Error Uploading Image. Please adjust the cropped area on the image and try again."
    },
    "preview": {
      "largeThumbnailLabel": {
        "context": "alt text on images",
        "source": "professional",
        "value": "Large Thumbnail Preview"
      },
      "mediumThumbnailLabel": {
        "context": "alt text on images",
        "source": "professional",
        "value": "Medium Thumbnail Preview"
      },
      "noImage": {
        "source": "professional",
        "value": "No image added"
      },
      "smallThumbnailLabel": {
        "context": "alt text on images",
        "source": "professional",
        "value": "Small Thumbnail Preview"
      },
      "subTitle": {
        "source": "professional",
        "value": "How your image will appear"
      },
      "tinyThumbnailLabel": {
        "context": "alt text on images, to provide context for assistive technology users",
        "source": "professional",
        "value": "Extra Small Thumbnail Preview"
      },
      "title": {
        "source": "professional",
        "value": "Preview"
      }
    }
  },
  "popover": {
    "zoomImage": {
      "source": "professional",
      "value": "Zoom Image"
    }
  },
  "programs": {
    "edit": {
      "tasks": {
        "addCaption": {
          "source": "professional",
          "value": "Add tasks"
        }
      },
      "topics": {
        "add": {
          "source": "professional",
          "value": "Add items"
        },
        "addTopics": {
          "source": "professional",
          "value": "Topics"
        }
      },
      "trimmedItems": {
        "source": "professional",
        "value": "Some targets were removed since they had no items within them. All targets must have content in them to be delivered."
      }
    }
  },
  "question": {
    "accessibility": {
      "correct": {
        "source": "professional",
        "value": "Correct."
      },
      "hotspotMarker": {
        "source": "professional",
        "value": "Hotspot Marker"
      },
      "incorrect": {
        "source": "professional",
        "value": "Incorrect."
      },
      "notSelected": {
        "source": "professional",
        "value": "Not selected."
      },
      "selected": {
        "source": "professional",
        "value": "Selected."
      }
    },
    "advMulti": {
      "source": "professional",
      "value": "Advanced Multiple Choice"
    },
    "answers": {
      "source": "professional",
      "value": "Answers"
    },
    "clicktoselect": {
      "source": "professional",
      "value": "Click to select"
    },
    "correct": {
      "source": "professional",
      "value": "Correct"
    },
    "explanation": {
      "source": "professional",
      "value": "Explanation"
    },
    "fill": {
      "source": "professional",
      "value": "Fill in the Blank"
    },
    "hotspot": {
      "source": "professional",
      "value": "Hotspot"
    },
    "incorrect": {
      "source": "professional",
      "value": "Incorrect"
    },
    "matching": {
      "source": "professional",
      "value": "Matching"
    },
    "multi": {
      "source": "professional",
      "value": "Multiple Choice"
    },
    "multiAnswer": {
      "source": "professional",
      "value": "Multiple Answer"
    },
    "picture": {
      "source": "professional",
      "value": "Picture"
    },
    "previewUnavailable": {
      "source": "professional",
      "value": "This question has been modified since you answered it. A preview is no longer available."
    },
    "translationRequired": {
      "source": "professional",
      "value": "Translation required"
    }
  },
  "quickLinks": {
    "title": {
      "source": "professional",
      "value": "Quick Links"
    }
  },
  "recognitionPins": {
    "pinImageLabel": {
      "source": "professional",
      "value": "Pin Image"
    }
  },
  "referral": {
    "expiredMessage": {
      "completed": {
        "source": "professional",
        "value": "Completed"
      }
    }
  },
  "reports": {
    "milestones": {
      "chartTitle": {
        "source": "professional",
        "value": "Completed on Time: 12 Month Trend"
      },
      "currentTeam": {
        "source": "professional",
        "value": "Current Team"
      },
      "empty": {
        "source": "professional",
        "value": "No topics matched the selected criteria."
      }
    }
  },
  "resetpassword": {
    "cant_find_user": {
      "source": "professional",
      "value": "The username and/or employee ID can't be found."
    },
    "confirmpassword": {
      "source": "professional",
      "value": "Confirm Password:"
    },
    "employeeid": {
      "source": "professional",
      "value": "Employee ID:"
    },
    "failedtoresetpassword": {
      "source": "professional",
      "value": "Failed to reset your password. Please check your credentials."
    },
    "help": {
      "source": "professional",
      "value": ""
    },
    "msg": {
      "source": "professional",
      "value": "Reset your current password"
    },
    "newpassword": {
      "source": "professional",
      "value": "New Password:"
    },
    "no_recovery_question": {
      "source": "professional",
      "value": "The user has not set up a recovery question. Please contact your administrator for assistance."
    },
    "title": {
      "source": "professional",
      "value": "Reset Your Password"
    },
    "user_not_activated": {
      "source": "professional",
      "value": "The account is not yet activated. Please log in to activate your account first."
    },
    "username": {
      "source": "professional",
      "value": "Username:"
    }
  },
  "rightPaneSelector": {
    "ariaLabel": {
      "context": "Aria label for right panel of the three pane layout page.",
      "source": "professional",
      "value": "Right panel that contains details of the list items on the left panel."
    }
  },
  "scorm": {
    "errorLoading": {
      "context": "No need to translate SCORM",
      "source": "professional",
      "value": "Error loading SCORM, please reload the page to try again. If this continues, contact your administrator."
    }
  },
  "search": {
    "placeholder": {
      "source": "professional",
      "value": "Search"
    }
  },
  "selfDirected": {
    "contents": {
      "itemCountPlural": {
        "context": "the number of items, more than 1",
        "source": "professional",
        "value": "<%- itemCount %> Items"
      },
      "itemCountSingular": {
        "context": "the number of item, only 1",
        "source": "professional",
        "value": "1 Item"
      },
      "taskCountPlural": {
        "context": "The number of Tasks, more than 1",
        "source": "professional",
        "value": "<%- itemCount %> Tasks"
      },
      "taskCountSingular": {
        "source": "professional",
        "value": "1 Task"
      }
    },
    "rteView": {
      "createLink": {
        "source": "professional",
        "value": "Create Link"
      },
      "embedVideo": {
        "source": "professional",
        "value": "Embed Video"
      },
      "error": {
        "invalidVideoCode": {
          "source": "professional",
          "value": "Invalid Video ID"
        }
      },
      "languageSelect": {
        "source": "professional",
        "value": "Select the language of the video you are going to upload"
      },
      "linkTitle": {
        "source": "professional",
        "value": "Insert Link URL"
      },
      "linkToExistingContent": {
        "source": "professional",
        "value": "Or link to existing content"
      },
      "providerLabel": {
        "source": "professional",
        "value": "Provider"
      },
      "removeLink": {
        "source": "professional",
        "value": "Remove Link"
      },
      "systemManagedWarning": {
        "source": "professional",
        "value": "This page is managed by <%- name %>. Changes to the title or body may be overridden by the system when the original source changes."
      },
      "uploadVideo": {
        "source": "professional",
        "value": "Upload Video"
      },
      "videoIdLabel": {
        "source": "professional",
        "value": "Video ID"
      }
    },
    "viewPathBtn": {
      "source": "professional",
      "value": "View Path"
    }
  },
  "session": {
    "logout": {
      "source": "professional",
      "value": "Log Out"
    }
  },
  "settings": {
    "company": {
      "importTypes": {
        "LOCATION_CODE": {
          "source": "professional",
          "value": "External Team Codes"
        }
      },
      "teamEdit": {
        "codeLabel": {
          "source": "professional",
          "value": "External Code:"
        },
        "exportTeams": {
          "source": "professional",
          "value": "Export Teams"
        }
      }
    },
    "hub": {
      "training": {
        "news": {
          "newsTypes": {
            "MilestoneAchievement": {
              "source": "professional",
              "value": "Milestone Achievement"
            }
          }
        }
      }
    },
    "notifications": {
      "agreements": {
        "confirmResend": {
          "source": "professional",
          "value": "You have made changes to the User Agreement. Would you like to re-send this agreement to all users?"
        },
        "deleteAgreement": {
          "source": "professional",
          "value": "Delete User Agreement"
        },
        "noMessages": {
          "source": "professional",
          "value": "There are no user agreements."
        },
        "pageTitle": {
          "source": "professional",
          "value": "User Agreement"
        }
      }
    }
  },
  "sortableHeader": {
    "alphanumeric": {
      "asc": {
        "source": "professional",
        "value": "<%= text %> - A to Z"
      },
      "desc": {
        "source": "professional",
        "value": "<%= text %> - Z to A"
      }
    },
    "date": {
      "asc": {
        "source": "professional",
        "value": "<%= text %> - Oldest"
      },
      "desc": {
        "source": "professional",
        "value": "<%= text %> - Recent"
      }
    },
    "defaultSorting": {
      "source": "professional",
      "value": "Default Sorting"
    },
    "numeric": {
      "asc": {
        "source": "professional",
        "value": "<%= text %> - Low to High"
      },
      "desc": {
        "source": "professional",
        "value": "<%= text %> - High to Low"
      }
    },
    "sortBy": {
      "source": "professional",
      "value": "Sort By: "
    }
  },
  "start": {
    "assessmentType": {
      "CertificationTraining": {
        "source": "professional",
        "value": "Certifications"
      },
      "DailyTraining": {
        "source": "professional",
        "value": "Daily Questions"
      },
      "ExtraTrainingQuestions": {
        "context": "Header title when in an assessment training session.",
        "source": "professional",
        "value": "Training"
      },
      "FormalExamTraining": {
        "source": "professional",
        "value": "Exams"
      },
      "GuidedLearningTraining": {
        "context": "Learning Path",
        "source": "professional",
        "value": "Path"
      },
      "IntroductoryTraining": {
        "source": "professional",
        "value": "New Topics"
      },
      "RefresherTraining": {
        "source": "professional",
        "value": "Refresher"
      },
      "evaluation": {
        "source": "professional",
        "value": "Evaluation"
      },
      "event": {
        "source": "professional",
        "value": "Event"
      }
    },
    "coach": {
      "welcomeCoach": {
        "source": "professional",
        "value": "Welcome coach"
      }
    },
    "homepage": {
      "continueTrainingProgressExtraTraining": {
        "source": "professional",
        "value": "<%- part %> of <%- total %> topics graduated"
      },
      "todaysActivities": {
        "source": "professional",
        "value": "Today's Activities"
      }
    },
    "startButton": {
      "certRetake": {
        "source": "professional",
        "value": "Re-Take Certification"
      },
      "certification": {
        "source": "professional",
        "value": "Start Certification"
      },
      "enroll": {
        "source": "professional",
        "value": "Enroll"
      },
      "evaluation": {
        "source": "professional",
        "value": "View Evaluation"
      },
      "exam": {
        "source": "professional",
        "value": "Start Exam"
      },
      "reassign": {
        "context": "Text for the button that will reassign a training item to a specific user.",
        "source": "professional",
        "value": "Reassign"
      },
      "resume": {
        "source": "professional",
        "value": "Resume"
      },
      "retake": {
        "source": "professional",
        "value": "Re-Take"
      },
      "start": {
        "source": "professional",
        "value": "Start"
      },
      "topic": {
        "source": "professional",
        "value": "Start Topic"
      },
      "topicRetake": {
        "source": "professional",
        "value": "Re-Take Topic"
      },
      "view": {
        "source": "professional",
        "value": "View"
      },
      "viewTask": {
        "source": "professional",
        "value": "View"
      }
    },
    "startButtonAriaLabel": {
      "certRetake": {
        "source": "professional",
        "value": "Re-Take certification: <%- name %>"
      },
      "certification": {
        "source": "professional",
        "value": "Start certification: <%- name %>"
      },
      "enroll": {
        "source": "professional",
        "value": "Enroll in <%- name %>"
      },
      "evaluation": {
        "source": "professional",
        "value": "View evaluation: <%- name %>"
      },
      "exam": {
        "source": "professional",
        "value": "Start exam: <%- name %>"
      },
      "reassign": {
        "context": "Aria label to indicated that this button will cause the specific training item to be reassigned. (Ex: Reassign Training: Power Equipment Certification)",
        "source": "professional",
        "value": "Reassign training: <%- name %>"
      },
      "resume": {
        "source": "professional",
        "value": "Resume training: <%- name %>"
      },
      "retake": {
        "source": "professional",
        "value": "Re-Take training: <%- name %>"
      },
      "start": {
        "source": "professional",
        "value": "Start training: <%- name %>"
      },
      "topic": {
        "source": "professional",
        "value": "Start topic: <%- name %>"
      },
      "topicRetake": {
        "source": "professional",
        "value": "Re-Take topic: <%- name %>"
      },
      "view": {
        "source": "professional",
        "value": "View: <%- name %>"
      },
      "viewTask": {
        "source": "professional",
        "value": "View task: <%- name %>"
      }
    }
  },
  "submission": {
    "infoCard": {
      "context": "desktop = desktop computer",
      "source": "professional",
      "value": "If you would like to download the submitted file, view this evaluation on desktop."
    }
  },
  "table": {
    "headers": {
      "name": {
        "source": "professional",
        "value": "Name"
      },
      "type": {
        "source": "professional",
        "value": "Type"
      }
    }
  },
  "tasks": {
    "assignment": {
      "title": {
        "source": "professional",
        "value": "Assignment"
      }
    },
    "create": {
      "context": "Display for button that opens a new form to create a new task",
      "source": "professional",
      "value": "Create Task"
    },
    "details": {
      "addComment": {
        "context": "Alternative text for Add Comment button in Task Details",
        "source": "professional",
        "value": "Add Comment"
      },
      "assignOwner": {
        "source": "professional",
        "value": "Assign Owner"
      },
      "commentAdded": {
        "source": "professional",
        "value": "Comment added to task."
      },
      "commentPlaceholder": {
        "context": "Placeholder text in Add Comment field of Task Details",
        "source": "professional",
        "value": "Comment"
      },
      "deleteTask": {
        "contextMenuButton": {
          "source": "professional",
          "value": "Action menu for task"
        },
        "oneTime": {
          "text": {
            "source": "professional",
            "value": "This will delete this task for all users and teams. This action cannot be undone."
          },
          "title": {
            "source": "professional",
            "value": "Delete Task?"
          }
        },
        "recurring": {
          "allOccurrences": {
            "source": "professional",
            "value": "All occurrences (including past occurrences)"
          },
          "thisTaskOccurrence": {
            "source": "professional",
            "value": "This task occurrence"
          },
          "title": {
            "source": "professional",
            "value": "Delete Repeating Task?"
          }
        }
      },
      "dueDateLabel": {
        "context": "Label for the due date of a task",
        "source": "professional",
        "value": "Due:"
      },
      "error": {
        "general": {
          "source": "professional",
          "value": "Something went wrong. Please try again later."
        },
        "taskNotExist": {
          "source": "professional",
          "value": "This task no longer exists. Please refresh the task list."
        }
      },
      "owner": {
        "source": "professional",
        "value": "Owner:"
      },
      "repeats": {
        "source": "professional",
        "value": "Repeats:"
      },
      "success": {
        "delete": {
          "source": "professional",
          "value": "Task has been deleted."
        }
      }
    },
    "dropzone": {
      "files": {
        "error": {
          "fileSizeTooBig": {
            "context": "Photo unable to upload. Max file size is 15MB.",
            "source": "professional",
            "value": "Photo unable to upload. Max file size is <%= maximumSize %>MB."
          },
          "fileTypeNotAllowed": {
            "source": "professional",
            "value": "Acceptable file types are .jpg and .png."
          }
        }
      }
    },
    "frequency": {
      "daily": {
        "context": "A task that must be completed everyday",
        "source": "professional",
        "value": "Daily"
      },
      "monthly": {
        "context": "A task that must be completed every month",
        "source": "professional",
        "value": "Monthly"
      },
      "oneTime": {
        "context": "A non-recurring task",
        "source": "professional",
        "value": "One&#8211;Time"
      },
      "recurring": {
        "context": "A task that must be done more than once",
        "source": "professional",
        "value": "Repeats"
      },
      "weekly": {
        "context": "A task that must be completed every week",
        "source": "professional",
        "value": "Weekly"
      }
    },
    "input": {
      "error": {
        "profanity": {
          "source": "professional",
          "value": "Unable to add comment due to inappropriate content. Please update and try again."
        },
        "tooLong": {
          "source": "professional",
          "value": "Comment exceeds max length. Please update and try again."
        },
        "unsafe": {
          "context": "Appears as a validation error below the input when the server has determined that the comment contains unsafe content.",
          "source": "professional",
          "value": "Unable to send due to invalid content. Please update and try again."
        }
      }
    },
    "message": {
      "onSave": {
        "source": "professional",
        "value": "Changes will be applied to tasks that have not yet started."
      },
      "onSaveNew": {
        "source": "professional",
        "value": "Task has been saved."
      },
      "oneTime": {
        "onSaveNew": {
          "source": "professional",
          "value": "Task created. It may take a moment to appear, and requires refreshing this page for it to show up."
        }
      },
      "recurring": {
        "onSaveNew": {
          "source": "professional",
          "value": "Task created. It will show on this page once it's available for completion."
        }
      }
    },
    "photoVerification": {
      "label": {
        "source": "professional",
        "value": "Photo Verification"
      },
      "noVerification": {
        "source": "professional",
        "value": "No Verification"
      },
      "noVerificationSubHeader": {
        "source": "professional",
        "value": "A photo is not required to mark the task as complete"
      },
      "photoRequiredText": {
        "source": "professional",
        "value": "Photo required for task completion"
      },
      "photoVerification": {
        "source": "professional",
        "value": "Photo Verification"
      },
      "photoVerificationSubHeader": {
        "source": "professional",
        "value": "Assignee must upload a photo to mark the task as complete"
      }
    },
    "recurrence": {
      "daily": {
        "context": "To show that a task 'Repeats: Daily' in the task details modal",
        "source": "professional",
        "value": "Daily"
      },
      "monthly": {
        "context": "To show that a task repeats monthly on a certain day of the month in the task details modal. Ex. 'Repeats: Monthly on day 5'",
        "source": "professional",
        "value": "Monthly on day <%- monthDay %>"
      },
      "multipleDays": {
        "context": "To show that a task 'Repeats: Every [n] days' in the task details modal",
        "source": "professional",
        "value": "Every <%- dayFrequency %> days"
      },
      "multipleMonths": {
        "context": "To show that a task 'Repeats: Every [n] months on day [day of the month]' in the tasks details modal",
        "source": "professional",
        "value": "Every <%- monthFrequency %> months on day <%- monthDay %>"
      },
      "multipleWeeks": {
        "context": "To show that a task 'Repeats: Every [n] weeks on [list of days of the week]' in the task details modal",
        "source": "professional",
        "value": "Every <%- weekFrequency %> weeks on <%- weekDays %>"
      },
      "weekly": {
        "context": "To show that a task repeats weekly on certain days in the task details modal. Ex. 'Repeats: Weekly on Tues, Thurs'",
        "source": "professional",
        "value": "Weekly on <%- weekDays %>"
      }
    },
    "schedule": {
      "caption": {
        "startTime": {
          "context": "This is the time a task will start on the start date",
          "source": "professional",
          "value": "Task generation starts at <%- time %> <%- timeZone %>"
        }
      },
      "days": {
        "source": "professional",
        "value": "day(s)"
      },
      "label": {
        "dayMonth": {
          "context": "Which day of the month the task will repeat",
          "source": "professional",
          "value": "on the <%- day %>"
        },
        "dueDate": {
          "source": "professional",
          "value": "Due Date"
        },
        "expiryDate": {
          "context": "The date this task will expire",
          "source": "professional",
          "value": "Expires"
        },
        "firstDueDate": {
          "context": "The first due date for the task",
          "source": "professional",
          "value": "First Occurrence Due Date"
        },
        "occurrencesExpire": {
          "source": "professional",
          "value": "Occurrences Expire"
        },
        "occurrencesStart": {
          "source": "professional",
          "value": "Occurrences Start"
        },
        "recursEvery": {
          "context": "How frequent a task should repeat",
          "source": "professional",
          "value": "Repeats every"
        },
        "repeatingEnds": {
          "context": "When the task will stop repeating",
          "source": "professional",
          "value": "Repeating Ends"
        },
        "startDate": {
          "context": "The Date that this task should be started",
          "source": "professional",
          "value": "Starts"
        },
        "taskExpires": {
          "source": "professional",
          "value": "Task Expires"
        },
        "taskStarts": {
          "source": "professional",
          "value": "Task Starts"
        }
      },
      "never": {
        "context": "Never end the task",
        "source": "professional",
        "value": "Never"
      },
      "occurrences": {
        "context": "How many times something has happened, 1 or more",
        "source": "professional",
        "value": "occurrence(s)"
      },
      "oneTime": {
        "source": "professional",
        "value": "One Time"
      },
      "oneTimeTaskWarning": {
        "source": "professional",
        "value": "Assignees cannot be added or modified once the task starts. This helps ensure accurate reporting."
      },
      "repeatTaskWarning": {
        "source": "professional",
        "value": "Assignees and other changes will update at the start of each occurrence. This helps ensure accurate reporting."
      },
      "repeats": {
        "source": "professional",
        "value": "Repeats"
      },
      "startDateNote": {
        "context": "Tast started on April 1st",
        "source": "professional",
        "value": "Task started on <%- startDate %>"
      },
      "taskGenerationNotice": {
        "source": "professional",
        "value": "Task generation starts at <%- time %>"
      },
      "timing": {
        "oneTime": {
          "taskExpire": {
            "after": {
              "source": "professional",
              "value": "After the due date & time"
            },
            "on": {
              "source": "professional",
              "value": "On the due date & time"
            }
          },
          "taskStart": {
            "before": {
              "source": "professional",
              "value": "Before the due date"
            },
            "on": {
              "source": "professional",
              "value": "On the due date"
            }
          }
        },
        "repeats": {
          "taskExpire": {
            "after": {
              "source": "professional",
              "value": "After each due date & time"
            },
            "next": {
              "source": "professional",
              "value": "At the start of the next occurrence"
            },
            "on": {
              "source": "professional",
              "value": "On each due date & time"
            }
          },
          "taskStart": {
            "before": {
              "source": "professional",
              "value": "Before each due date"
            },
            "on": {
              "source": "professional",
              "value": "On each due date"
            }
          }
        }
      },
      "title": {
        "source": "professional",
        "value": "Schedule"
      },
      "tooltip": {
        "occurrencesExpireTooltip": {
          "source": "professional",
          "value": "When the occurrence is no longer available for completion"
        },
        "occurrencesStartTooltip": {
          "source": "professional",
          "value": "When the occurrence becomes available for completion"
        },
        "taskExpiresTooltip": {
          "source": "professional",
          "value": "When the task is no longer available for completion"
        },
        "taskStartsTooltip": {
          "source": "professional",
          "value": "When the task becomes available for completion"
        }
      }
    },
    "steps": {
      "addStep": {
        "context": "Add step of a task",
        "source": "professional",
        "value": "Add Step"
      },
      "confirmation": {
        "commentLabelPlural": {
          "context": "Please describe why 1 of 8 steps were not completed.",
          "source": "professional",
          "value": "Please describe why <%= notCompletedSteps %> of <%= totalSteps %> steps were not completed."
        },
        "commentLabelSingular": {
          "source": "professional",
          "value": "Please describe why the step was not completed."
        },
        "messagePlural": {
          "context": "1 of 8 steps are incomplete. If any remaining steps aren’t needed for this task, you can still mark it as complete.",
          "source": "professional",
          "value": "<%= notCompletedSteps %> of <%= totalSteps %> steps are incomplete. If any remaining steps aren’t needed for this task, you can still mark it as complete."
        },
        "messageSingular": {
          "source": "professional",
          "value": "The step is incomplete. If the remaining step isn’t needed for this task, you can still mark it as complete."
        },
        "photoVerificationMessagePlural": {
          "context": "1 of 8 steps are incomplete. If any remaining steps aren’t needed for this task, you can still upload a photo and mark it as complete.",
          "source": "professional",
          "value": "<%= notCompletedSteps %> of <%= totalSteps %> steps are incomplete. If any remaining steps aren’t needed for this task, you can still upload a photo and mark it as complete."
        },
        "photoVerificationMessageSingular": {
          "source": "professional",
          "value": "The step is incomplete. If the remaining step isn’t needed for this task, you can still upload a photo and mark it as complete."
        },
        "submit": {
          "source": "professional",
          "value": "Finish Marking as Complete"
        }
      },
      "label": {
        "context": "Step of a task",
        "source": "professional",
        "value": "Step"
      },
      "markComplete": {
        "source": "professional",
        "value": "Mark step as complete:"
      },
      "markIncomplete": {
        "source": "professional",
        "value": "Mark step as incomplete:"
      },
      "title": {
        "context": "Steps of a task",
        "source": "professional",
        "value": "Steps"
      }
    },
    "view": {
      "context": "Display for button that redirects to the task page",
      "source": "professional",
      "value": "View Tasks"
    }
  },
  "teamSelector": {
    "dialog": {
      "viewTeam": {
        "source": "professional",
        "value": "View Team"
      }
    },
    "openTeamButton": {
      "source": "professional",
      "value": "Open Team"
    },
    "selectTeam": {
      "source": "professional",
      "value": "Select a Team"
    }
  },
  "time": {
    "durationFormat": {
      "days": {
        "source": "professional",
        "value": "<%- days %>d "
      },
      "hours": {
        "source": "professional",
        "value": "<%- hours %>h "
      },
      "minutes": {
        "source": "professional",
        "value": "<%- minutes %>m "
      },
      "seconds": {
        "source": "professional",
        "value": "<%- seconds %>s"
      }
    },
    "title": {
      "context": "Screen reader label for time selector",
      "source": "professional",
      "value": "Time Picker"
    }
  },
  "timezone": {
    "title": {
      "source": "professional",
      "value": "Time Zone"
    }
  },
  "training": {
    "cards": {
      "actions": {
        "discover": {
          "source": "professional",
          "value": "Go to <%= t('appNames.knowledge') %>"
        },
        "view": {
          "source": "professional",
          "value": "View"
        }
      },
      "discoverTitle": {
        "source": "professional",
        "value": "Looking for more?"
      },
      "info": {
        "averageScore": {
          "source": "professional",
          "value": "<%- score %>% Average"
        },
        "averageScoreWithLevel": {
          "source": "professional",
          "value": "<%- score %>% Average - Level <%- level %>"
        },
        "boostConfidence": {
          "source": "professional",
          "value": "Boost Confidence"
        },
        "boostKnowledge": {
          "source": "professional",
          "value": "Boost Knowledge"
        },
        "certified": {
          "source": "professional",
          "value": "Certified - Level <%- level %>"
        },
        "complete": {
          "source": "professional",
          "value": "Complete"
        },
        "completedOfTotal": {
          "source": "professional",
          "value": "<%- completed %> of <%- total %> Complete"
        },
        "discoverDescription": {
          "source": "professional",
          "value": "Join your co-workers, search for more paths and topics that interest you."
        },
        "needsCoaching": {
          "source": "professional",
          "value": "Needs Coaching"
        },
        "notStarted": {
          "source": "professional",
          "value": "Not Started"
        },
        "numAvailable": {
          "source": "professional",
          "value": "<%- numAvailable %> available"
        },
        "overdue": {
          "source": "professional",
          "value": "Overdue"
        },
        "progressLevelBeginner": {
          "source": "professional",
          "value": "Beginner - Level <%- level %>"
        },
        "progressLevelExpert": {
          "source": "professional",
          "value": "Advanced - Level <%- level %>"
        },
        "progressLevelGraduate": {
          "source": "professional",
          "value": "Graduated"
        },
        "progressLevelIntermediate": {
          "source": "professional",
          "value": "Intermediate - Level <%- level %>"
        },
        "progressLevelNotStarted": {
          "source": "professional",
          "value": "Not started - Level <%- level %>"
        }
      },
      "tags": {
        "assigned": {
          "source": "professional",
          "value": "Assigned"
        },
        "certification": {
          "source": "professional",
          "value": "Certification"
        },
        "enrolled": {
          "source": "professional",
          "value": "Enrolled"
        },
        "path": {
          "source": "professional",
          "value": "Path"
        },
        "reassigned": {
          "context": "Tag for Path training programs that have been reassigned by a user's manager.",
          "source": "professional",
          "value": "Reassigned"
        },
        "recommendation": {
          "source": "professional",
          "value": "Recommendation"
        },
        "topic": {
          "source": "professional",
          "value": "Topic"
        }
      },
      "viewWithCardTitle": {
        "source": "professional",
        "value": "View <%- title %>"
      }
    },
    "menu": {
      "achievements": {
        "source": "professional",
        "value": "Achievements"
      },
      "askMax": {
        "context": "Max is the name an AI assistant (like Siri or Alexa)",
        "source": "professional",
        "value": "Max"
      },
      "auctions": {
        "source": "professional",
        "value": "Rewards"
      },
      "certifications": {
        "source": "professional",
        "value": "Certifications"
      },
      "extratraining": {
        "source": "professional",
        "value": "Extra Training"
      },
      "formalExams": {
        "source": "professional",
        "value": "Exams"
      },
      "gamescores": {
        "source": "professional",
        "value": "Game Scores"
      },
      "guidedLearning": {
        "context": "Learning Path",
        "source": "professional",
        "value": "Path"
      },
      "home": {
        "context": "homepage",
        "source": "professional",
        "value": "Home"
      },
      "knowledgemap": {
        "source": "professional",
        "value": "Report Card"
      },
      "leaderboards": {
        "source": "professional",
        "value": "Top Performers"
      },
      "learnerTraining": {
        "source": "professional",
        "value": "Training"
      },
      "learningEvents": {
        "source": "professional",
        "value": "Events"
      },
      "newTopics": {
        "source": "professional",
        "value": "New Topics"
      },
      "newsfeed": {
        "source": "professional",
        "value": "Feed"
      },
      "nudgeTasks": {
        "source": "professional",
        "value": "Tasks"
      },
      "profile": {
        "source": "professional",
        "value": "Profile"
      },
      "referral": {
        "source": "professional",
        "value": "Tell-A-Friend"
      },
      "refresher": {
        "source": "professional",
        "value": "Refresher Training"
      },
      "search": {
        "context": "Non-translatable app name originally used for the Discover app and now used as the name of our LearnerZone search feature",
        "source": "professional",
        "value": "<%= t('appNames.knowledge') %>"
      },
      "selfDirectedPaths": {
        "source": "professional",
        "value": "Path"
      },
      "tasks": {
        "source": "professional",
        "value": "To-Dos"
      },
      "timeline": {
        "source": "professional",
        "value": "Timeline"
      },
      "timelineAriaLabel": {
        "source": "professional",
        "value": "Timeline - Unread Items"
      },
      "trainingpage": {
        "source": "professional",
        "value": "Training"
      }
    },
    "noTrainingMessage": {
      "source": "professional",
      "value": "You have no training to do today. Check back next time!"
    },
    "todaysTrainingTitle": {
      "source": "professional",
      "value": "Today's Training"
    }
  },
  "trainingModule": {
    "videoPlayer": {
      "source": "professional",
      "value": "Video player"
    }
  },
  "translatableName": {
    "characterLimit": {
      "context": "Maximum number of characters permitted in an input field",
      "source": "professional",
      "value": "<%- maxLength %> character limit"
    },
    "note": {
      "source": "professional",
      "value": "If there is no translation available for a user's set language, the default platform language will be shown"
    }
  },
  "treeEditor": {
    "loading": {
      "source": "professional",
      "value": "Loading..."
    },
    "loadingError": {
      "source": "professional",
      "value": "There was an error loading."
    },
    "more": {
      "source": "professional",
      "value": "More..."
    },
    "noDataGeneric": {
      "source": "professional",
      "value": "No data."
    }
  },
  "treeview": {
    "edit": {
      "attributes": {
        "bulk": {
          "modal": {
            "error": {
              "source": "professional",
              "value": "Some options could not be applied"
            },
            "notFound": {
              "source": "professional",
              "value": "Options not found"
            },
            "selected": {
              "source": "professional",
              "value": "Selected Options"
            },
            "title": {
              "context": "Header for the modal that has 'attribute name - Bulk Selection'",
              "source": "professional",
              "value": "<%- attribute %> - Bulk Selection"
            }
          },
          "title": {
            "context": "Shows on hover over bulk edit button",
            "source": "professional",
            "value": "Edit Selection"
          }
        }
      }
    }
  },
  "userDateCondition": {
    "dateConditionTypes": {
      "afterSpecificDate": {
        "source": "professional",
        "value": "After a specific date"
      },
      "beforeSpecificDate": {
        "source": "professional",
        "value": "Before a specific date"
      },
      "beyondNumOfDays": {
        "context": "dateType will either be Hire Date or Role Start Date",
        "source": "professional",
        "value": "Starting after <%- dateType %>"
      },
      "withinNumOfDays": {
        "context": "dateType will either be Hire Date or Role Start Date",
        "source": "professional",
        "value": "Starting on <%- dateType %>"
      }
    },
    "dateTypes": {
      "hireDate": {
        "source": "professional",
        "value": "Hire Date"
      },
      "roleStartDate": {
        "source": "professional",
        "value": "Role Start Date"
      }
    },
    "previewText": {
      "afterSpecificDate": {
        "context": "dateTypeVerb will either be 'hired' or 'started in role'. specificDate will be a date like 'Aug 8, 2024'",
        "source": "professional",
        "value": "Users <%- dateTypeVerb %> after <%- specificDate %>"
      },
      "beforeSpecificDate": {
        "context": "dateTypeVerb will either be 'hired' or 'started in role'. specificDate will be a date like 'Aug 8, 2024'",
        "source": "professional",
        "value": "Users <%- dateTypeVerb %> before <%- specificDate %>"
      },
      "beyondNumOfDays": {
        "context": "numOfDays is a number and dateTypeString will either be Hire Date or Role Start Date",
        "source": "professional",
        "value": "Starting <%- numOfDays %> days after <%- dateTypeString %>"
      },
      "beyondNumOfDaysSingular": {
        "context": "dateTypeString will either be Hire Date or Role Start Date. For example: Starting 1 day after Hire Date",
        "source": "professional",
        "value": "Starting 1 day after <%- dateTypeString %>"
      },
      "verbs": {
        "hireDate": {
          "source": "professional",
          "value": "hired"
        },
        "roleStartDate": {
          "source": "professional",
          "value": "started in role"
        }
      },
      "withinNumOfDays": {
        "context": "numOfDays is a number and dateTypeString will either be Hire Date or Role Start Date",
        "source": "professional",
        "value": "Starting on <%- dateTypeString %>, for <%- numOfDays %> days"
      },
      "withinNumOfDaysSingular": {
        "context": "dateTypeString will either be Hire Date or Role Start Date. For example: Starting on Hire Date, for 1 day",
        "source": "professional",
        "value": "Starting on <%- dateTypeString %>, for 1 day"
      }
    }
  },
  "users": {
    "edit": {
      "error": {
        "password": {
          "complexityError": {
            "source": "professional",
            "value": "The password does not meet our complexity requirements. It must have characters in <%- minNumberOfCharacterGroups %> of the following 4 groups: uppercase letter, lowercase letter, number or symbol."
          },
          "missmatch": {
            "source": "professional",
            "value": "Passwords don't match."
          },
          "tooShort": {
            "source": "professional",
            "value": "The password is too short. The minimum length is <%- minPasswordLength %> characters."
          }
        }
      }
    },
    "new": {
      "error": {
        "password": {
          "complexityError": {
            "source": "professional",
            "value": "The password does not meet our complexity requirements. It must have characters in <%- minNumberOfCharacterGroups %> of the following 4 groups: uppercase Latin character, lowercase Latin character, number or symbol."
          },
          "missmatch": {
            "source": "professional",
            "value": "Passwords don't match."
          },
          "tooShort": {
            "source": "professional",
            "value": "The password is too short. The minimum length is <%- minPasswordLength %> characters."
          }
        }
      }
    },
    "password": {
      "error": {
        "recycled": {
          "context": "Error message shown to user when the provided password can not be used because it fails the tenant's password recycling policy",
          "source": "professional",
          "value": "You've used this password recently. Please choose a different one."
        }
      },
      "rules": {
        "complexityRule": {
          "lowercase": {
            "source": "professional",
            "value": "&nbsp;   &#150;  Lowercase Latin characters"
          },
          "number": {
            "source": "professional",
            "value": "&nbsp;   &#150;  Numbers"
          },
          "symbol": {
            "source": "professional",
            "value": "&nbsp;   &#150;  Symbols"
          },
          "title": {
            "source": "professional",
            "value": "&#149; Contains <%- minNumberOfCharacterGroups %> out of 4 of the following:"
          },
          "uppercase": {
            "source": "professional",
            "value": "&nbsp;   &#150;  Uppercase Latin characters"
          }
        },
        "lengthRule": {
          "source": "professional",
          "value": "&#149; At least <%- minPasswordLength %> characters long"
        },
        "title": {
          "source": "professional",
          "value": "Password Rules:"
        }
      }
    },
    "securityAnswer": {
      "error": {
        "duplicate": {
          "context": "Failed to update the answers to the User's security questions because there is a duplicate amongst thte answers",
          "source": "professional",
          "value": "Duplicate recovery answer provided. All recovery answers must be unique."
        },
        "tooShort": {
          "context": "Failed to update the answers to the User's security questions due to at least one of the answers being too short",
          "source": "professional",
          "value": "Recovery answer too short. Minimum length is <%- minLength %>, but provided length is <%- actualLength %>."
        }
      }
    }
  },
  "video": {
    "defaultError": {
      "source": "professional",
      "value": "This video is currently unavailable, please try again later."
    },
    "emptyTranscodingsProcessing": {
      "source": "professional",
      "value": "Video is still processing. It may take up to 30 minutes to complete."
    },
    "emptyTranscodingsReturn": {
      "source": "professional",
      "value": "Please check back later."
    },
    "failedTranscodingsProcessing": {
      "source": "professional",
      "value": "Video Processing Failed."
    },
    "failedTranscodingsReturn": {
      "source": "professional",
      "value": "Please contact your administrator."
    },
    "loadingWMP": {
      "source": "professional",
      "value": "Loading Windows Media Player components..."
    },
    "pause": {
      "source": "professional",
      "value": "Pause"
    },
    "play": {
      "context": "play a video",
      "source": "professional",
      "value": "Play"
    },
    "title": {
      "source": "professional",
      "value": "Video"
    }
  }
};